import {Helmet} from "react-helmet";
import {Link } from "react-router-dom";
const Seoserv = ()=>{
    return(
      <>
         

        <div className="header-spacer" />
        <div className="content-wrapper">
            <div className="stunning-header stunning-header-bg-violet">
                <div className="stunning-header-content">
                    <h1 className="stunning-header-title">Seo Services</h1>
                    <ul className="breadcrumbs">
                    <li className="breadcrumbs-item">
                        <Link to="/">Home</Link>
                        <i className="seoicon-right-arrow" />
                    </li>
                    <li className="breadcrumbs-item active">
                        <span href="#">Seo Services</span>
                        <i className="seoicon-right-arrow" />
                    </li>
                    </ul>
                </div>
            </div>

            <div className="overlay_search">
                <div className="container">
                    <div className="row">
                        <div className="form_search-wrap">
                            <form>
                                <input className="overlay_search-input" placeholder="Type and hit Enter..." type="text" />
                                <a href="#" className="overlay_search-close">
                                    <span />
                                    <span />
                                </a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-6">

                {/* <div className="row">
                    <div className="col-lg-12">

                    </div>
                </div> */}

                <h4 className="h1 heading-title">SEO & website design: Everything you need to know</h4>
                <div className="heading-line">
                    <span className="short-line" />
                    <span className="long-line" />
                </div>
                <div className="row pb30">
                    <div className="col-lg-12">
                        <p className="heading-text">Your website is the center of your digital marketing world — the place that all digital rivers run toward. And of course, the largest of its traffic sources is generally organic search.</p>
                        <p className="heading-text">Yet all too often, businesses don’t think about SEO until after having a website designed (or redesigned), and these sites are often sadly lacking on the SEO and digital marketing front. They may look shiny, but if the marketing smarts are not cooked in at design time, then you will be running the marketing race with a wooden leg. Or at the very least, faced with going back to the drawing board and wasting a whole load of time and money.</p>
                        <p className="heading-text">We have been thinking about the SEO and web design connection a lot recently at Bowler Hat and have just published a website design planning guide to help in what can be a complicated process. This is a companion piece to that guide that really covers the SEO considerations in far more granular detail.</p>
                        <p className="heading-text">In this post, I have a look at how SEO should be an integral part of your website design (or redesign) process. We are going to look at what you need to consider to have a site that is built for search marketing and lead generation — and how focusing on happy users keeps the Google gods on your side.</p>
                        <p className="heading-text">We will also take a look at some of the common pitfalls that can befall businesses looking to build a new website that is central to your digital marketing efforts.
                            In brief, I am going to help you ensure your next site is a lean, mean SEO and digital marketing machine.</p>
                    </div>
                </div>
                <h4 className="h1 heading-title">Developing an SEO-friendly website</h4>
                <div className="row pb30">
                    <div className="col-lg-12">
                    <p>At a fundamental level, an SEO-friendly site is one that allows a search engine to explore and read pages across the site. Ensuring a search engine can easily crawl and understand your content is the first step to ensuring your visibility in the search engine result pages.</p>
                
                <p>A search engine utilizes a web crawler for this task, and we are trying to work with the search engines rather than against them. Unfortunately, there are many ways to make a website, and not all technologies are built with search engine optimization in mind.</p>
            
                <p>Building an SEO-friendly site requires careful planning and a structured approach to representing your business and the services you provide. For many businesses, this can be complicated — it’s not always easy to document exactly what you do.</p>
                <p>As a marketing tool, your website should be built upon a solid digital marketing plan with a clear business model and value proposition. If that’s unclear, then you need to revisit that first. Assuming you have all that good stuff in place, let’s dive in.</p>
                    </div>
                   
                </div>
                <h4 className="h1 heading-title">Fundamentals</h4>
                <div className="row pb30">
                    <div className="col-lg-12">
                    <p><strong>domain</strong>Your business may use example.com as the primary domain. But you may have others. Ensuring your domain makes sense and relates to what you do is super-important. Ensuring that all variations and subdomains correctly point at the main site and redirect to a single canonical version of the site is important.

Our business is called Bowler Hat. We operate in the UK. We are a web-based business. It naturally follows that our domain is www.bowlerhat.co.uk. All subdomains 301 redirect back to the main URL www.bowlerhat.co.uk. We have few domain variations that 301 redirect back to the main URL. This all makes sense.

Don’t be fooled into thinking that having-my-keywords-in-my-domain.com helps. It just looks daft. It can help a little for local businesses, but ensure you are mapping to the real world. Be sensible.</p>
                    <p><strong>Hosting</strong>Your hosting is also important. A slow site makes for unhappy users. Your hosting should follow common-sense rules. Be situated where your audience is situated. Be fast. Be platform-specific, if necessary. WP Engine is a great example, as it provides a platform tailored to WordPress websites.</p>
                    <p><strong>CMS</strong>The CMS (content management system) you choose for your business can hugely influence how successful you are. WordPress is a great option in many situations, but it’s not the only one. It certainly is wired up at a basic level in a way that Google can understand. This is not to say it is the best choice for all situations, but certainly, it’s a good starting point for most businesses. Just be sure that the CMS you choose is the right one for your situation, rather than the one your chosen web company prefers to work with.</p>  
                    </div>
                   
                </div>
                <h4 className="h1 heading-title">Mobile-friendly design</h4>
                <div className="row pb30">
                    <div className="col-lg-12">
                        <p>The most popular device used to conduct internet searches and to browse websites is the mobile phone. We live in a mobile-first age. Sites optimized for search engines should give equal consideration to the mobile layouts of their websites (rather than just bolting on simple responsive website design).</p>
                        <p>Yet, in 2017, responsive design is not enough. We were talking about the importance of responsive website design in 2012. Five years later, with massive technological progress and greatly improved mobile data networks, your future customers are using mobile as the first, and often only, device to interact with your business.</p>
                        <p>To create a truly mobile-friendly design and maximize results from mobile search, you must think of the needs and wants of mobile users. What a user will do on a phone is often far different from what they will do on a computer. And even if your conversions tend to be on a desktop, that crucial first touch may well be on mobile.</p>
                        <p>A few months back, I looked at 28 key factors in creating mobile SEO-friendly websites that will help you move beyond simple mobile-friendly responsive design.</p>
                        <p>Work hard on optimizing the user experience for mobile users and you will reap the rewards for your efforts in terms of traffic and user engagement.</p>
                    </div>
                </div>
                <h4 className="h1 heading-title">Page speed</h4>
                <div className="row pb30">
                    <div className="col-lg-12">
                    <p>Another key consideration in the mobile era is page speed. Users may be impatient, or they may not always have a great mobile data connection. Ensuring your pages are lean and mean is a key consideration in modern SEO-friendly website design.</p>
                    <p>A great starting point is Google’s mobile-friendly test. This tool will give you feedback on mobile-friendliness, mobile speed and desktop speed. It also wraps everything up into a handy little report detailing what exactly you can do to speed things up.</p>
                    <p>I went into a little more detail on how to optimize for speed in a recent column on mobile optimization. Suffice it to say, page speed is yet another important consideration that spans how your site is built and the quality and suitability of the hosting you use.</p>
                    </div>
                   
                </div>
                <h4 className="h1 heading-title">Image optimization</h4>
                <div className="row pb30">
                    <div className="col-lg-12">
                    <p>Image SEO can drive a substantial amount of traffic in the right circumstances. And again, our thoughts regarding context are important here. Google does not (yet) use the content of images, so context within the site and the page and basic optimization are crucial here.</p>
                    <p>As an example, I am looking for a hobbit hole playhouse for my five-year-old, and the search brings up image results:</p>
                    </div>
                   
                </div>
            </div>        
        </div>
      </>
    );
  }
 
  export default Seoserv;