import React from 'react';
import Footer from '../Component/Footer';
import Header from '../Component/Header';
import {Helmet} from "react-helmet";
import {Link } from "react-router-dom";



const Carrer=()=>{
    return(
        <>

        
        <div>
 <div className="header-spacer" />
  <div className="content-wrapper">
    {/* Stunning header */}
    <div className="stunning-header stunning-header-bg-lightblue">
      <div className="stunning-header-content">
        <h1 className="stunning-header-title">Career</h1>
        <ul className="breadcrumbs">
          <li className="breadcrumbs-item">
            <Link to="/">Home</Link>
            <i className="seoicon-right-arrow" />
          </li>
          <li className="breadcrumbs-item active">
            <span>Career</span>
            <i className="seoicon-right-arrow" />
          </li>
        </ul>
      </div>
    </div>
    {/* End Stunning header */}
	{/* Contact form */}
    <div className="container">
      <div className="contact-form pt-6 pb-6">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="heading">
              <h4 className="heading-title">Career</h4>
              <div className="heading-line">
                <span className="short-line" />
                <span className="long-line" />
              </div>
              <p className="heading-text">Please contact us using the form and we’ll get back to you as soon as possible.</p>
            </div>
          </div>
        </div>
        <form className="contact-form" method="POST" action="https://formspree.io/f/xknaonjo">
          <div className="row">
            
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <input name="name" className="email input-standard-grey" placeholder="Your Name" type="text"  autoComplete="off" required />
            </div>
			<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <input name="email" className="email input-standard-grey" placeholder="Email Address" type="email"  autoComplete="off" required />
            </div>
          </div>
          <div className="row">
            
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <input name="phone" className="email input-standard-grey" placeholder="Phone" type="text"  autoComplete="off" required/>
            </div>
			
			<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <input name="subject" className="email input-standard-grey" placeholder="Subject" type="text"  autoComplete="off" required />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <textarea name="message" className="email input-standard-grey" placeholder="Details"  autoComplete="off" required />
            </div>
          </div>
          <div className="row">
            <div className="submit-block table">
              <div className="col-lg-3 table-cell">
              <input type="submit" className="btn-raised btn btn-small btn--primary" value="Submit Now" /> 
                {/* <button className="btn btn-small btn--primary">
                  <span className="text">Submit Now</span>
                </button> */}
              </div>
              <div className="col-lg-5 table-cell">
                
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    {/* End Contact form */}
	

  </div>
</div>
        </>
    )
}

export default Carrer;


