
const Comment = (props)=>{

    const {comment, comImage, commetPerson, commentDate, commentTime} = props;

    return(
        <li className="comments__item">
            <div className="comment-entry comment comments__article">
                <div className="comment-content comment">
                    <p>{comment}</p>
                </div>
                <div className="comments__body display-flex">
                    <a href="#" className="reply">
                        <i className=" seoicon-arrow-back"></i>
                    </a>
                    <div className="comments__avatar">
                        <img src={comImage} alt="avatar"/>
                        
                    </div>
                    <header className="comment-meta comments__header">
                        <cite className="fn url comments__author">
                            <a href="#" rel="external" className=" ">{commetPerson}</a>
                        </cite>
                        <div className="comments__time">
                            <time className="published" datetime="2016-04-20 12:00:00">{commentDate}
                                <span className="at">at</span>{commentTime}
                            </time>
                        </div>
                    </header>
                </div>
            </div>
        </li>
    )
}
export default Comment;