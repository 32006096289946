import {Link } from "react-router-dom";

const Ourproject = ()=>{
    return(
<>
    <div className="container">
		<div className="row medium-padding120">
			<div className="recent-case align-center">

				<div className="col-lg-12">

					<div className="row">
						<div className="col-lg-10 col-lg-offset-1 col-md-8 col-md-offset-2 col-sm-12 col-xs-12">
							<div className="heading align-center">
								<h4 className="h1 heading-title">Our Projects</h4>
								<div className="heading-line">
									<span className="short-line"></span>
									<span className="long-line"></span>
								</div>
								<p className="heading-text">PLUX Business and Technology Services help enterprises become future ready with its offerings across the magento, laravel, codeigniter, React, React native and PHP.
								</p>
							</div>
						</div>
					</div>

                    <div className="row">
						<div className="case-item-wrap">
                            <div className="col-lg-4  col-md-4 col-sm-6 col-xs-12 mb-3">
                                <div className="case-item">
                                    <div className="case-item__thumb">
                                    <a href="https://web.kailashchandra.com/pscs/front/" target="_blank">
                                        <img src="img/pscs-p-1.png" alt="our case" />
                                    </a>
                                    </div>
                                    <h6 className="case-item__title">Palm Springs Car Storage</h6>
                                </div>
                            </div>

							<div className="col-lg-4  col-md-4 col-sm-6 col-xs-12 mb-3">
                                <div className="case-item">
                                    <div className="case-item__thumb">
                                    <a href="https://wecharge.co.in/" target="_blank">
                                        <img src="img/wecharge-p-1.png" alt="our case" />
                                    </a>
                                    </div>
                                    <h6 className="case-item__title">Wecharge Fulling India</h6>
                                </div>
                            </div>

							<div className="col-lg-4  col-md-4 col-sm-6 col-xs-12 mb-3">
                                <div className="case-item">
                                    <div className="case-item__thumb mouseover poster-3d lightbox shadow animation-disabled" data-offset={5}>
                                    <a href="https://tipsterchallenge.com/pluxinfo_2021/" target="_blank">
                                        <img src="img/tipster-p-1.png" alt="our case" />
                                    </a>
                                    </div>
                                    <h6 className="case-item__title">Tipster challenge</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4  col-md-4 col-sm-6 col-xs-12">
                                    <div className="case-item">
                                        <div className="case-item__thumb mouseover poster-3d lightbox shadow animation-disabled" data-offset={5}>
                                        <a href="http://web.kailashchandra.com/cashewprotect/" target="_blank">
                                            <img src="img/cashew-p-1.png" alt="cashew protect" />
                                        </a>
                                        </div>
                                        <h6 className="case-item__title">CASHEW PROTECT</h6>
                                    </div>
                                    </div>
                                            <div className="col-lg-4  col-md-4 col-sm-6 col-xs-12">
                                    <div className="case-item mb-3">
                                        <div className="case-item__thumb mouseover poster-3d lightbox shadow animation-disabled" data-offset={5}>
                                        <a href="https://web.kailashchandra.com/tosall_client/" target="_blank">
                                            <img src="img/tosall-p-1.png" alt="chatbot" />
                                        </a>
                                        </div>
                                        <h6 className="case-item__title">AI CHATBOT</h6>
                                    </div>
                                            <div className="case-item mt-6">
                                        <div className="case-item__thumb mouseover poster-3d lightbox shadow animation-disabled" data-offset={5}>
                                        <a href="http://surveys.authenticopinion.com/clientpanel" target="_blank">
                                            <img src="img/trueopinion-p-1.png" alt="chatbot" />
                                        </a>
                                        </div>
                                        <h6 className="case-item__title">TRUEOPINION</h6>
                                    </div>
                                    </div>
                                            <div className="col-lg-4  col-md-4 col-sm-6 col-xs-12 mb-3">
                                    <div className="case-item">
                                        <div className="case-item__thumb">
                                        <a href="https://fashionzapp.com/" target="_blank">
                                            <img src="img/fashionzapp.png" alt="our case" />
                                        </a>
                                        </div>
                                        <h6 className="case-item__title">fashionzapp</h6>
                                    </div>
                                </div>
                            </div>
						</div>
					</div>

					<Link to="portfolio" className="btn btn-medium btn--dark">
						<span className="text">All Projects</span>
						<span className="semicircle"></span>
					</Link>
				</div>

			</div>
		</div>
	</div>
</>
)}

export default Ourproject;