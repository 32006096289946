import { useState, useEffect } from "react";
import axios from 'axios';
import Article from './Article';
import Latestnews from './Latestnews';
import PostCate from './PostCate';
import Socialsec from './Socialsec';
import {Link} from 'react-router-dom';

const socialicon = [               
    {SocialSite:"Facebook", SocialIcon:"seoicon-social-facebook", colorClass:"facebook-bg-color"},
    {SocialSite:"Twitter", SocialIcon:"seoicon-social-twitter", colorClass:"twitter-bg-color"},
    {SocialSite:"Linkedin", SocialIcon:"seoicon-social-linkedin", colorClass:"linkedin-bg-color"},
    {SocialSite:"Google+", SocialIcon:"seoicon-social-google-plus", colorClass:"google-bg-color"},
    {SocialSite:"Pinterest", SocialIcon:"seoicon-social-pinterest", colorClass:"pinterest-bg-color"},
];

const articaldata = [
    {
        postDate:'12/12/12', 
        postImg:"img/post1.jpg",
        postTitle:"Custom Web Design dfghbdfbjb",
        postDetail:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.'
    },
    {
        postDate:'12/12/12', 
        postImg:"img/post1.jpg",
        postTitle:"Custom Web Design",
        postDetail:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.'
    },
    {
        postDate:'12/12/12', 
        postImg:"img/post1.jpg",
        postTitle:"Custom Web Design",
        postDetail:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.'
    },
    {
        postDate:'12/12/12', 
        postImg:"img/post1.jpg",
        postTitle:"Custom Web Design",
        postDetail:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.'
    },
    {
        postDate:'12/12/12', 
        postImg:"img/post1.jpg",
        postTitle:"Custom Web Design",
        postDetail:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.'
    },
    {
        postDate:'12/12/12', 
        postImg:"img/post1.jpg",
        postTitle:"Custom Web Design",
        postDetail:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.'
    },
    {
        postDate:'12/12/12', 
        postImg:"img/post1.jpg",
        postTitle:"Custom Web Design",
        postDetail:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.'
    },
    {
        postDate:'12/12/12', 
        postImg:"img/post1.jpg",
        postTitle:"Custom Web Design",
        postDetail:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.'
    },
    {
        postDate:'12/12/12', 
        postImg:"img/post1.jpg",
        postTitle:"Custom Web Design",
        postDetail:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.'
    },
    {
        postDate:'12/12/12', 
        postImg:"img/post1.jpg",
        postTitle:"Custom Web Design",
        postDetail:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.'
    }
]




const Blog = ()=>{
    const [myData, setMyData] = useState([]);
  
    const getApiData = async () => {
          const res = await axios.get("https://api.pluxinfosystems.com/wp-json/wp/v2/posts");
          setMyData(res.data);
      };

      useEffect(() => {
        getApiData();
      }, []);

    return(
        <>
        <div className="header-spacer" />
            <div className="stunning-header stunning-header-bg-lightblue">
                <div className="stunning-header-content">
                    <h1 className="stunning-header-title">Blogs</h1>
                    <ul className="breadcrumbs">
                        <li className="breadcrumbs-item">
                            <Link to="/">Home</Link>
                            <i className="seoicon-right-arrow" />
                        </li>
                        <li className="breadcrumbs-item active">
                            <span>Blogs</span>
                            <i className="seoicon-right-arrow" />
                        </li>
                    </ul>
                </div>
            </div>
            <div className="container">
                <div className="row medium-padding120">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    {
                        myData.slice(0, 5).map((post) => {
                            return(
                                <>
                                        <main className="main">
                                            <Article
                                                postDate={post.date}
                                                postid={post.id}
                                                postTitle={post.title.rendered}
                                                postDetail={post.excerpt.rendered}
                                            />
                                        </main>
                                    
                                </>
                            )
                        })

                    }
                    </div>
                    <div className="col-lg-3 col-lg-offset-1 col-md-4 col-sm-12 col-xs-12">
                        <aside aria-label="sidebar" className="sidebar sidebar-right">
                          {/*   <div className="widget">
                                <form className="w-search">
                                    <input className="email search input-standard-grey" required="required" placeholder="Search" type="search"/>
                                    <button className="icon">
                                        <i className="seoicon-loupe"></i>
                                    </button>
                                </form>
                            </div>
                            <div className="widget w-post-category">
                                <div className="heading">
                                    <h4 className="heading-title">Post Category</h4>
                                    <div className="heading-line">
                                        <span className="short-line"></span>
                                        <span className="long-line"></span>
                                    </div>
                                </div>
                                <div className="post-category-wrap">
                                   <PostCate
                                        postCount="102"
                                        postTitle="web development"
                                   />
                                   <PostCate
                                        postCount="67"
                                        postTitle="web designing"
                                   />
                                </div>
                            </div>

                            <div className="widget w-latest-news">
                                <div className="heading">
                                    <h4 className="heading-title">Latest News</h4>
                                    <div className="heading-line">
                                        <span className="short-line"></span>
                                        <span className="long-line"></span>
                                    </div>
                                </div>

                                <div className="latest-news-wrap">
                                  <Latestnews
                                    newsTitle="Qemonstraverunt legere"
                                    newsDate=" March 25, 2016"
                                    newstime="12:05 pm"
                                  />

                                </div>

                                <a href="#" className="btn btn-small btn--dark btn-hover-shadow">
                                    <span className="text">All News</span>
                                    <i className="seoicon-right-arrow"></i>
                                </a>
                            </div> 

                            <div className="widget w-follow">
                                <div className="heading">
                                    <h4 className="heading-title">Follow Us</h4>
                                    <div className="heading-line">
                                        <span className="short-line"></span>
                                        <span className="long-line"></span>
                                    </div>
                                </div>

                                <div className="w-follow-wrap">
                                    {socialicon.map((social)=>{
                                       return(
                                            <Socialsec
                                                SocialSite={social.SocialSite}
                                                SocialIcon={social.SocialIcon}
                                                colorClass={social.colorClass}
                                            />
                                        ) 
                                    })}
                                </div>
                            </div>*/}

                            <div className="widget w-tags">
                                <div className="heading">
                                    <h4 className="heading-title">Popular Tags</h4>
                                    <div className="heading-line">
                                        <span className="short-line"></span>
                                        <span className="long-line"></span>
                                    </div>
                                </div>

                                <div className="tags-wrap">
                                    <a href="#" className="w-tags-item">SEO</a>
                                    <a href="#" className="w-tags-item">Advertising</a>
                                    <a href="#" className="w-tags-item">Business</a>
                                    <a href="#" className="w-tags-item">Optimization</a>
                                    <a href="#" className="w-tags-item">Digital Marketing</a>
                                    <a href="#" className="w-tags-item">Social</a>
                                    <a href="#" className="w-tags-item">Keyword</a>
                                    <a href="#" className="w-tags-item">Strategy</a>
                                    <a href="#" className="w-tags-item">Audience</a>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div> 
            </div> 

           
        </>
    )
}

export default Blog;