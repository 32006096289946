import React, { Component } from "react";
import Slider from "react-slick";

export default class Valuableclients extends Component {
  render() {
    const pscsstyle = {
        backgroundColor:"#060607",
      }
    const settings = {
      dots: false,
      infinite: true,
      arrows: false,
      speed: 2000,
      slidesToShow: 5,
      autoplay: true,   
      autoplaySpeed: 1500,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="valuable">
        <Slider {...settings}>
        <div>
            <div className="swiper-slide client-item">
                  <a href="https://www.acap.edu.au/" target="_blank" className="client-image">
                    <img src="img/logo-acap.png" alt="logo" className="hover" />
                  </a>
            </div>
          </div>
          <div>
            <div className="swiper-slide client-item">
                  <a href="https://thedateroom.com/" target="_blank" className="client-image">
                    <img src="img/logo-date_room.png" alt="logo" className="hover" />
                  </a>
            </div>
          </div>
          
          <div>
            <div className="swiper-slide client-item">
                  <a href="http://web.kailashchandra.com/cashewprotect/" target="_blank" className="client-image">
                    <img src="img/logo-cashew.png" alt="logo" className="hover" />
                  </a>
            </div>
          </div>
          <div>
            <div className="swiper-slide client-item">
                  <a href="https://www.dept.store/" target="_blank" className="client-image">
                    <img src="img/logo-deptstore.png" alt="logo" className="hover" />
                  </a>
            </div>
          </div>


          <div>
            <div className="swiper-slide client-item">
                  <a href="https://pyurvana.com/" target="_blank" className="client-image">
                    <img src="img/logo-pyurvana.png" alt="logo" className="hover" />
                  </a>
            </div>
          </div>

          <div>
          <div className="swiper-slide client-item">
                  <a href="http://surveys.authenticopinion.com/clientpanel" target="_blank" className="client-image">
                    <img src="img/logo-trueopinion.png" alt="logo" className="hover" />
                  </a>
                </div>
          </div>
          <div>
          <div className="swiper-slide client-item">
                  <a href="https://thewindowdepot.com/" target="_blank" className="client-image">
                    <img src="img/logo-window_depot.png" alt="logo" className="hover" />
                  </a>
                </div>
          </div>

          <div>
          <div className="swiper-slide client-item">
                  <a href="https://sae.edu.au/" target="_blank" className="client-image">
                    <img src="img/logo-sae.png" alt="logo" className="hover" />
                  </a>
                </div>
          </div>



          <div>
          <div className="swiper-slide client-item">
                  <a href="https://web.kailashchandra.com/tosall_client/" target="_blank" className="client-image">
                    <img src="img/tosall-logo-plux.png" alt="logo" className="hover" />
                  </a>
                </div>
          </div>
          <div>
            <div className="swiper-slide client-item">
                <a href="https://tipsterchallenge.com/pluxinfo_2021/" target="_blank" className="client-image">
                <img src="img/logo-tipster.jpg" alt="logo" className="hover" />
                </a>
            </div>
          </div>
          <div>
            <div className="swiper-slide client-item">
                <a href="https://www.wecharge.co.in/" target="_blank" className="client-image">
                <img src="img/logo-wechage.png" alt="logo" className="hover" />
                </a>
            </div>
          </div>
          <div>
            <div className="swiper-slide client-item">
                <a href="https://www.wla.edu.au/" target="_blank" className="client-image">
                <img src="img/logo-wla.png" alt="logo" className="hover" />
                </a>
            </div>
          </div>
          <div> 
            <div className="swiper-slide client-item">
                <a href="https://web.kailashchandra.com/pscs/front" target="_blank" className="client-image">
                <img src="img/logo-pscs.png" alt="logo" className="hover" style={pscsstyle}/>
                </a>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}