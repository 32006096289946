import {Helmet} from "react-helmet";
import {Link } from "react-router-dom";
 const Ecommerce = ()=>{
   return(
      <>
         

        <div className="header-spacer" />
        <div className="content-wrapper ecommerce">
          <div className="stunning-header stunning-header-bg-violet">
            <div className="stunning-header-content">
                <h1 className="stunning-header-title">Ecommerce Solutions</h1>
                <ul className="breadcrumbs">
                <li className="breadcrumbs-item">
                    <Link to="/">Home</Link>
                    <i className="seoicon-right-arrow" />
                </li>
                <li className="breadcrumbs-item active">
                    <span>Ecommerce Solutions</span>
                    <i className="seoicon-right-arrow" />
                </li>
                </ul>
            </div>
          </div>
          <div className="container pt-6">
            <h4 className="h1 heading-title">Ecommerce Solutions</h4>
            <div className="row pb30">
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className="servises-item">
                  <div className="servises-item__thumb">
                   <img src="img/plux_ecommerce2.png"/>
                  </div>
                  <div className="servises-item__content">
                    <h4 className="servises-title">Squarespace Commerce</h4>
                    <p className="servises-text">Market your ecommerce business and drive sales</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className="servises-item">
                  <div className="servises-item__thumb">
                   <img src="img/plux_ecommerce3.png"/>
                  </div>
                  <div className="servises-item__content">
                    <h4 className="servises-title">Squarespace Commerce</h4>
                    <p className="servises-text">Market your ecommerce business and drive sales</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div className="servises-item">
                  <div className="servises-item__thumb">
                   <img src="img/plux_ecommerce1.png"/>
                  </div>
                  <div className="servises-item__content">
                    <h4 className="servises-title">Squarespace Commerce</h4>
                    <p className="servises-text">Market your ecommerce business and drive sales</p>
                  </div>  
                </div>
              </div>
            </div>
          </div>  
          <section className="social_icon">
            <div className="container pt-6">
              <h4 className="h1 heading-title">Convert leads into contacts</h4>
              <div className="row pb30">
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div className="servises-item">
                    <div className="servises-item__thumb">
                    <img src="img/plux_ecommerceicon1.png"/>
                    </div>
                    <div className="servises-item__content">
                      <h4 className="servises-title">LEAD GENERATION FORMS</h4>
                      <p className="servises-text">Sync and connect with leads from pre-filled forms</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div className="servises-item">
                    <div className="servises-item__thumb">
                    <img src="img/plux_ecommerceicon2.png"/>
                    </div>
                    <div className="servises-item__content">
                      <h4 className="servises-title">SOCIAL LEADS</h4>
                      <p className="servises-text">Generate leads from signup forms</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div className="servises-item">
                    <div className="servises-item__thumb">
                    <img src="img/plux_ecommerceicon3.png"/>
                    </div>
                    <div className="servises-item__content">
                      <h4 className="servises-title">LEAD GENERATION</h4>
                      <p className="servises-text">Gather leads directly from the video player</p>
                    </div>  
                  </div>
                </div>
              </div>
            </div>  
          </section>
        </div>
      </>
   );
 }

 export default Ecommerce;