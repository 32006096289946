import React, { Component } from 'react';
import {Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './Portfolio1.css'; 

const TabOne = [
    {
        image: 'samsung-brandcart',
        bigImage: 'img/portfolio1/main/dp-portfolio-samsung-brandcart.png',
        category: 'Magento',
        websiteName:'SAMSUNG BRANDCART',
        title: 'An ecommerce website',
        siteurl:'https://samsung.brandcart.co.ke/'
    },
    {
        image: 'thewindowdepot',
        bigImage: 'img/portfolio1/main/dp-portfolio-thewindowdepot.png',
        category: 'Magento',
        websiteName:'THE WINDOW DEPOT',
        title: 'An ecommerce website',
		siteurl:'https://thewindowdepot.com/'
    },
    {
        image: 'pyurvana',
        bigImage: 'img/portfolio1/main/dp-portfolio-pyurvana.png',
        category: 'Magento',
        title: 'An ecommerce website',
        websiteName:'PYURVANA',
		siteurl:'https://pyurvana.com/'
    },
    {
        image: 'deptstore',
        bigImage: 'img/portfolio1/main/dp-portfolio-deptstore.png',
        category: 'Magento',
        title: 'An ecommerce website',
        websiteName:'DEPTSTORE',
		siteurl:'http://dept.store/'
    },
    {
        image: 'khaleejtools',
        bigImage: 'img/portfolio1/main/dp-portfolio-khaleejtools.png',
        category: 'Magento',
        websiteName:'KHALEEJTOOLS',
        title: 'An ecommerce website',
		siteurl:'https://www.khaleejtools.com/'
    },
    {
        image: 'fabercart',
        bigImage: 'img/portfolio1/main/dp-portfolio-fabercart.png',
        category: 'Magento',
        websiteName:'FABERCART',
        title: 'An ecommerce website',
		siteurl:'https://faberindia.com/'
    },
    {
        image: 'cuffsnlashes',
        bigImage: 'img/portfolio1/main/dp-portfolio-cuffsnlashes.png',
        category: 'Magento',
        title: 'An ecommerce website',
        websiteName:'CUFFSNLASHES',
		siteurl:'https://cuffsnlashes.com/'
    },
    {
        image: 'tosall',
        bigImage: 'img/portfolio1/main/dp-portfolio-tosall.png',
        category: 'Codeigniter',
        websiteName:'TOSALL',
        title: 'This is a chatbot admin panel with front side',
		siteurl:'https://www.tosall.com/'
    },

    {
        image: 'wecharge',
        bigImage: 'img/portfolio1/main/dp-portfolio-wecharge.png',
        category: 'PHP',
        websiteName:'WECHARGE',
        title: 'Fuel and Diesel refueliing website with four panel and mobile app',
		siteurl:'https://wecharge.co.in/'
    },
    {
        image: 'tipster_challenge',
        bigImage: 'img/portfolio1/main/dp-portfolio-tipster_challenge.png',
        category: 'PHP',
        websiteName:'TIPSTER CHALLENGE',
        title: 'This is a Global and Innovative FREE Horse Racing, Competition based on UK Horse Racing',
		siteurl:'http://tipsterchallenge.com/'
    },

    {
        image: 'whatsdoingapp',
        bigImage: 'img/portfolio1/main/dp-portfolio-whatsdoingapp.png',
        category: 'Codeigniter',
        websiteName:'WHATSDOINGAPP',
        title: 'This is an admin panel and API developed for mobile app development',
		siteurl:'http://admindev.whatsdoingapp.com/admin/login'
    },
    {
        image: 'palmspringscarstorage_admin',
        bigImage: 'img/portfolio1/main/dp-portfolio-palmspringscarstorage_admin.png',
        category: 'Codeigniter',
        websiteName:'PALMSPRINGSCARSTORAGE ADMIN',
        title: 'This is an admin panel to access and control all the car which are stored',
		siteurl:'https://palmspringscarstorage.net/admin/'
    },
    {
        image: 'true-opinion',
        bigImage: 'img/portfolio1/main/dp-portfolio-true-opinion.png',
        category: 'Laravel',
        websiteName:'TRUE-OPINION',
        title: 'This is an PAID SURVEY WEBSITE with admin panel (Laravel)',
		siteurl:'https://www.true-opinion.com/'
    },
    {
        image: 'acap',
        bigImage: 'img/portfolio1/main/dp-portfolio-acap.png',
        category: 'Wordpress',
        websiteName:'ACAP | Australian College of Applied Professions',
        title: 'Australian College of Applied Professions',
		siteurl:'https://www.acap.edu.au/' 
    },
    {
        image: 'saeeduau',
        bigImage: 'img/portfolio1/main/dp-portfolio-saeeduau.png',
        category: 'Wordpress',
        websiteName:'SAE CREATIVE MEDIA INSTITUTE',
        title: 'FOR YOUR CREATIVE CAREER BEGINS NOW',
		siteurl:'https://sae.edu.au/'
    },
    {
        image: 'balajimariline',
        bigImage: 'img/portfolio1/main/dp-portfolio-balajimariline.png',
        category: 'Wordpress',
        websiteName:'BALAJIMARILINE',
        title: 'servicing the Indian and foreign exporters and Importers',
		siteurl:'https://www.balajimariline.com/'
    },
    {
        image: 'ellasbubbles',
        bigImage: 'img/portfolio1/main/dp-portfolio-ellasbubbles.png',
        category: 'Wordpress',
        title: 'An ecommerce website',
        websiteName:'ELLASBUBBLES',
		siteurl:'https://ellasbubbles.com/'
    },
    {
        image: 'mensfitclub',
        bigImage: 'img/portfolio1/main/dp-portfolio-mensfitclub.png',
        category: 'Wordpress',
        websiteName:'MENSFITCLUB',
        title: 'This is related about health and gym',
		siteurl:'https://www.mensfitclub.com/'
    },
    {
        image: 'wla',
        bigImage: 'img/portfolio1/main/dp-portfolio-wla.png',
        category: 'Wordpress',
        websiteName:'Women Leadership Australia',
        title: 'This is related about Women Leadership Australia',
		siteurl:'https://www.wla.edu.au/'
    }

]


const TabTwo = [
    {
        image: 'samsung-brandcart',
        bigImage: 'img/portfolio1/main/dp-portfolio-samsung-brandcart.png',
        category: 'Magento',
        websiteName:'SAMSUNG BRANDCART',
        title: 'An ecommerce website',
        siteurl:'https://samsung.brandcart.co.ke/'
    },
    {
        image: 'thewindowdepot',
        bigImage: 'img/portfolio1/main/dp-portfolio-thewindowdepot.png',
        category: 'Magento',
        websiteName:'THE WINDOW DEPOT',
        title: 'An ecommerce website',
		siteurl:'https://thewindowdepot.com/'
    },
    {
        image: 'pyurvana',
        bigImage: 'img/portfolio1/main/dp-portfolio-pyurvana.png',
        category: 'Magento',
        title: 'An ecommerce website',
        websiteName:'PYURVANA',
		siteurl:'https://pyurvana.com/'
    },
    {
        image: 'deptstore',
        bigImage: 'img/portfolio1/main/dp-portfolio-deptstore.png',
        category: 'Magento',
        title: 'An ecommerce website',
        websiteName:'DEPTSTORE',
		siteurl:'http://dept.store/'
    },
    {
        image: 'khaleejtools',
        bigImage: 'img/portfolio1/main/dp-portfolio-khaleejtools.png',
        category: 'Magento',
        websiteName:'KHALEEJTOOLS',
        title: 'An ecommerce website',
		siteurl:'https://www.khaleejtools.com/'
    },
    {
        image: 'fabercart',
        bigImage: 'img/portfolio1/main/dp-portfolio-fabercart.png',
        category: 'Magento',
        websiteName:'FABERCART',
        title: 'An ecommerce website',
		siteurl:'https://faberindia.com/'
    },
    {
        image: 'cuffsnlashes',
        bigImage: 'img/portfolio1/main/dp-portfolio-cuffsnlashes.png',
        category: 'Magento',
        title: 'An ecommerce website',
        websiteName:'CUFFSNLASHES',
		siteurl:'https://cuffsnlashes.com/'
    },
   
]

const TabThree = [
    {
        image: 'tipster_challenge',
        bigImage: 'img/portfolio1/main/dp-portfolio-tipster_challenge.png',
        category: 'PHP',
        websiteName:'TIPSTER CHALLENGE',
        title: 'This is a Global and Innovative FREE Horse Racing, Competition based on UK Horse Racing',
		siteurl:'http://tipsterchallenge.com/'
    },
    {
        image: 'wecharge',
        bigImage: 'img/portfolio1/main/dp-portfolio-wecharge.png',
        category: 'PHP',
        websiteName:'WECHARGE',
        title: 'Fuel and Diesel refueliing website with four panel and mobile app',
		siteurl:'https://wecharge.co.in/'
    },
]

const TabFour = [
    {
        image: 'true-opinion',
        bigImage: 'img/portfolio1/main/dp-portfolio-true-opinion.png',
        category: 'Laravel',
        websiteName:'TRUE-OPINION',
        title: 'This is an PAID SURVEY WEBSITE with admin panel (Laravel)',
		siteurl:'https://www.true-opinion.com/'
    },
]
const TabFive = [
    {
        image: 'tosall',
        bigImage: 'img/portfolio1/main/dp-portfolio-tosall.png',
        category: 'Codeigniter',
        websiteName:'TOSALL',
        title: 'This is a chatbot admin panel with front side',
		siteurl:'https://www.tosall.com/'
    },
    {
        image: 'wecharge',
        bigImage: 'img/portfolio1/main/dp-portfolio-wecharge.png',
        category: 'Codeigniter',
        websiteName:'WECHARGE',
        title: 'Fuel and Diesel refueliing website with four panel and mobile app',
		siteurl:'https://wecharge.co.in/'
    },
    {
        image: 'whatsdoingapp',
        bigImage: 'img/portfolio1/main/dp-portfolio-whatsdoingapp.png',
        category: 'Codeigniter',
        websiteName:'WHATSDOINGAPP',
        title: 'This is an admin panel and API developed for mobile app development',
		siteurl:'http://admindev.whatsdoingapp.com/admin/login'
    },
    {
        image: 'palmspringscarstorage_admin',
        bigImage: 'img/portfolio1/main/dp-portfolio-palmspringscarstorage_admin.png',
        category: 'Codeigniter',
        websiteName:'PALMSPRINGSCARSTORAGE ADMIN',
        title: 'This is an admin panel to access and control all the car which are stored',
		siteurl:'https://palmspringscarstorage.net/admin/'
    },
]
const TabSix = [
    {
        image: 'acap',
        bigImage: 'img/portfolio1/main/dp-portfolio-acap.png',
        category: 'Wordpress',
        websiteName:'ACAP | Australian College of Applied Professions',
        title: 'Australian College of Applied Professions',
		siteurl:'https://www.acap.edu.au/' 
    },
    {
        image: 'saeeduau',
        bigImage: 'img/portfolio1/main/dp-portfolio-saeeduau.png',
        category: 'Wordpress',
        websiteName:'SAE CREATIVE MEDIA INSTITUTE',
        title: 'FOR YOUR CREATIVE CAREER BEGINS NOW',
		siteurl:'https://sae.edu.au/'
    },
    {
        image: 'balajimariline',
        bigImage: 'img/portfolio1/main/dp-portfolio-balajimariline.png',
        category: 'Wordpress',
        websiteName:'BALAJIMARILINE',
        title: 'servicing the Indian and foreign exporters and Importers',
		siteurl:'https://www.balajimariline.com/'
    },
    {
        image: 'ellasbubbles',
        bigImage: 'img/portfolio1/main/dp-portfolio-ellasbubbles.png',
        category: 'Wordpress',
        title: 'An ecommerce website',
        websiteName:'ELLASBUBBLES',
		siteurl:'https://ellasbubbles.com/'
    },
    {
        image: 'mensfitclub',
        bigImage: 'img/portfolio1/main/dp-portfolio-mensfitclub.png',
        category: 'Wordpress',
        websiteName:'MENSFITCLUB',
        title: 'This is related about health and gym',
		siteurl:'https://www.mensfitclub.com/'
    },
    {
        image: 'wla',
        bigImage: 'img/portfolio1/main/dp-portfolio-wla.png',
        category: 'Wordpress',
        websiteName:'Women Leadership Australia',
        title: 'This is related about Women Leadership Australia',
		siteurl:'https://www.wla.edu.au/'
    }
]

class TabStyleThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            tab2: 0,
            tab3: 0,
            tab4: 0,
            tab5: 0,
            tab6: 0,
            isOpen: false,
        };
    }
    render() {
        const { tab1, tab2, tab3, tab4, tab5, tab6, isOpen } = this.state;
        return (
            <div>
              <div className="header-spacer" />
    <div className="stunning-header stunning-header-bg-rose">
      <div className="stunning-header-content">
        <h1 className="stunning-header-title">Our Portfolio</h1>
        <ul className="breadcrumbs">
          <li className="breadcrumbs-item">
            <Link to="/">Home</Link>
            <i className="seoicon-right-arrow" />
          </li>
          <li className="breadcrumbs-item active">
            <span href="#">Our Portfolio</span>
            <i className="seoicon-right-arrow" />
          </li>
        </ul>
      </div>
    </div>    
    <div className='container'>
      <div className="row medium-padding120">
          <div className="col-lg-12">
            <div className="heading align-center">
                <h4 className="h1 heading-title">We Help Over 80 Companies</h4>
            </div>
            <section  data-id="portfolio">
              <div className="section-inner">
                <Tabs>
                    <div className="row text-center">
                        <div className="col-lg-12">
                            <div className="tablist-inner">
                                <TabList className="pv-tab-button text-center mt--0">
                                    <Tab><span>All Projects</span></Tab>
                                    <Tab><span>Magento2</span></Tab>
                                    <Tab><span>PHP</span></Tab>
                                    <Tab><span>Laravel</span></Tab>
                                    <Tab><span>CodeIgniter</span></Tab>
                                    <Tab><span>Wordpress</span></Tab>
                                </TabList>
                            </div>
                        </div>
                    </div>

                    <TabPanel className="row row--35">
                        {TabOne.map((value , index) => (
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabOne[tab1].bigImage}
                                        nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                                        prevSrc={TabOne[(tab1 + TabOne.length - 1) % TabOne.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab1: (tab1 + 1) % TabOne.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                        <div className="portfolio-static case-item align-center mb60">
                                            <div className="thumbnail-inner case-item__thumb">
                                                <div className="thumbnails">
                                                    <a href="#portfolio-details">
                                                        <img src={`img/portfolio1/main/dp-portfolio-${value.image}.png`} alt="Portfolio img"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                <p><a href={value.siteurl} className='case-item__title'  target="_blank" rel="noopener noreferrer">{value.websiteName}</a></p>
                                                    <h6 className='case-item__title' >{value.title}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>
            
                    <TabPanel className="row row--35">
                        {TabTwo.map((value , index) => (
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabTwo[tab2].bigImage}
                                        nextSrc={TabTwo[(tab2 + 1) % TabTwo.length]}
                                        prevSrc={TabTwo[(tab2 + TabTwo.length - 1) % TabTwo.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab2: (tab2 + TabTwo.length - 1) % TabTwo.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab2: (tab2 + 1) % TabTwo.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab2: index })}>
                                        <div className="portfolio-static case-item align-center mb60">
                                            <div className="thumbnail-inner case-item__thumb">
                                                <div className="thumbnails">
                                                    <a href="#portfolio-details">
                                                        <img src={`img/portfolio1/main/dp-portfolio-${value.image}.png`} alt="Portfolio img"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                <p><a href={value.siteurl} className='case-item__title'  target="_blank" rel="noopener noreferrer">{value.websiteName}</a></p>
                                                    <h6 className='case-item__title' >{value.title}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabThree.map((value , index) => (
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabThree[tab3].bigImage}
                                        nextSrc={TabThree[(tab3 + 1) % TabThree.length]}
                                        prevSrc={TabThree[(tab3 + TabThree.length - 1) % TabThree.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab3: (tab3 + TabThree.length - 1) % TabThree.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab3: (tab3 + 1) % TabThree.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab3: index })}>
                                        <div className="portfolio-static case-item align-center mb60">
                                            <div className="thumbnail-inner case-item__thumb">
                                                <div className="thumbnails">
                                                    <a href="#portfolio-details">
                                                        <img src={`img/portfolio1/main/dp-portfolio-${value.image}.png`} alt="Portfolio img"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                <p><a href={value.siteurl} className='case-item__title'  target="_blank" rel="noopener noreferrer">{value.websiteName}</a></p>
                                                    <h6 className='case-item__title' >{value.title}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabFour.map((value , index) => (
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabFour[tab4].bigImage}
                                        nextSrc={TabFour[(tab4 + 1) % TabFour.length]}
                                        prevSrc={TabFour[(tab4 + TabFour.length - 1) % TabFour.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab4: (tab4 + TabFour.length - 1) % TabFour.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab4: (tab4 + 1) % TabFour.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static case-item align-center mb60">
                                            <div className="thumbnail-inner case-item__thumb">
                                                <div className="thumbnails">
                                                    <a href="#portfolio-details">
                                                        <img src={`img/portfolio1/main/dp-portfolio-${value.image}.png`} alt="Portfolio img"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                <p><a href={value.siteurl} className='case-item__title'  target="_blank" rel="noopener noreferrer">{value.websiteName}</a></p>
                                                    <h6 className='case-item__title' >{value.title}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>
                    <TabPanel className="row row--35">
                        {TabFive.map((value , index) => (
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabFive[tab5].bigImage}
                                        nextSrc={TabFive[(tab5 + 1) % TabFive.length]}
                                        prevSrc={TabFive[(tab5 + TabFive.length - 1) % TabFive.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab5: (tab5 + TabFive.length - 1) % TabFive.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab5: (tab5 + 1) % TabFive.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, TabFive: index })}>
                                        <div className="portfolio-static case-item align-center mb60">
                                            <div className="thumbnail-inner case-item__thumb">
                                                <div className="thumbnails">
                                                    <a href="#portfolio-details">
                                                        <img src={`img/portfolio1/main/dp-portfolio-${value.image}.png`} alt="Portfolio img"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                   <p><a href={value.siteurl} className='case-item__title'  target="_blank" rel="noopener noreferrer">{value.websiteName}</a></p>
                                                    <h6 className='case-item__title' >{value.title}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>
                    <TabPanel className="row row--35">
                        {TabSix.map((value , index) => (
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={TabSix[tab6].bigImage}
                                        nextSrc={TabSix[(tab6 + 1) % TabSix.length]}
                                        prevSrc={TabSix[(tab6 + TabSix.length - 1) % TabSix.length]}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            tab6: (tab6 + TabSix.length - 1) % TabSix.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                            this.setState({
                                                tab6: (tab6 + 1) % TabSix.length,
                                            })
                                        }
                                        imageLoadErrorMessage = 'Image Loading ...'
                                        enableZoom={false}
                                    />
                                )}
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab6: index })}>
                                        <div className="portfolio-static case-item align-center mb60">
                                            <div className="thumbnail-inner case-item__thumb">
                                                <div className="thumbnails">
                                                    <a href="#portfolio-details">
                                                        <img src={`img/portfolio1/main/dp-portfolio-${value.image}.png`} alt="Portfolio img"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p><a href={value.siteurl} className='case-item__title' target="_blank" rel="noopener noreferrer">{value.websiteName}</a></p>
                                                    <h6 className='case-item__title'>{value.title}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>



                </Tabs>
                </div>
            </section>
          </div>
          </div>
       </div>
     </div>
        )
    }
}


export default TabStyleThree
