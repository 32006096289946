const Socialsec = (props)=>{
    const {SocialSite, SocialIcon, colorClass} = props;
    return(
        <div className={`${colorClass} w-follow-item`}>

            {/*facebook-bg-color*/}
            <a href="#" className="w-follow-social__item table-cell">
                <i className={SocialIcon}></i>
                {/* seoicon-social-facebook */}
            </a>
            <a href="#" className="w-follow-title table-cell">{SocialSite}
                <span className="w-follow-add">
                <i className="seoicon-cross plus"></i>
                <i className="seoicon-check-bold check"></i>
            </span>
            </a>
        </div>
    )
}
export default Socialsec;