import { useState, useEffect } from "react";
import DetailArticle from './DetailArticle';
import Comment from './Comment';
import PostCate from './PostCate';
import Socialsec from './Socialsec';
import Latestnews from './Latestnews';

const socialicon = [               
    {SocialSite:"Facebook", SocialIcon:"seoicon-social-facebook", colorClass:"facebook-bg-color"},
    {SocialSite:"Twitter", SocialIcon:"seoicon-social-twitter", colorClass:"twitter-bg-color"},
    {SocialSite:"Linkedin", SocialIcon:"seoicon-social-linkedin", colorClass:"linkedin-bg-color"},
    {SocialSite:"Google+", SocialIcon:"seoicon-social-google-plus", colorClass:"google-bg-color"},
    {SocialSite:"Pinterest", SocialIcon:"seoicon-social-pinterest", colorClass:"pinterest-bg-color"},
];
const Blogdetail = ()=>{
    return(
<>
    <div className="container">
        <div className="row medium-padding120">
            <main className="main">
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <DetailArticle
                        author="admin"
                        date="12/12/2021"
                        blogCat="web devlopment"
                        subTitle="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore."
                        testiText="Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis placerat facer possim assum."
                        discription="Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum quam nunc putamus parum claram, anteposuerit litterarum formas"
                        authorName="vikash kumar"
                        authorComp="pluxinfosystems"
                        blogImg="img/blog-details.jpg"
                        blogTitle="STANDARD POST FORMAT"
                    />

                    <div className="comments">
                        <div className="heading">
                            <h4 className="h1 heading-title">6 Comments</h4>
                            <div className="heading-line">
                                <span className="short-line"></span>
                                <span className="long-line"></span>
                            </div>
                        </div>

                        <ol className="comments__list">

                            <Comment
                                comment="Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta et quinta decima."
                                comImage="img/post-author3.png"
                                commetPerson="Jonathan Simpson"
                                commentDate="12/12/2021"
                                commentTime="12:40 pm"
                            />
                        </ol>
                    </div>

                    <div className="row">

                        <div className="leave-reply contact-form">

                            <form>

                                <div className="col-lg-12">
                                    <div className="heading">
                                        <h4 className="h1 heading-title">Leave a Comment</h4>
                                        <div className="heading-line">
                                            <span className="short-line"></span>
                                            <span className="long-line"></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">

                                    <input className="email input-standard-grey" name="full_name" id="full_name" placeholder="Your Full Name" value="" type="text"/>
                                </div>

                                <div className="col-lg-6">

                                    <input className="email input-standard-grey" name="comment_email" id="comment_email" placeholder="Email Address" value="" type="text"/>

                                </div>

                                <div className="col-lg-6">

                                    <input className="email input-standard-grey" name="comment_website" id="comment_website" placeholder="Website" value="" type="text"/>

                                </div>

                                <div className="col-lg-12">

                                    <textarea name="order_comments" className="input-text input-standard-grey" id="order_comments" placeholder="Your Comment"></textarea>

                                </div>
                                <div className="col-lg-12">
                                    <div className="submit-block table">
                                        <div className="col-lg-4 table-cell">
                                            <div className="btn btn-small btn--primary">
                                                <span className="text">Submit</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-lg-offset-1 col-md-4 col-sm-12 col-xs-12">
                    <aside aria-label="sidebar" className="sidebar sidebar-right">
                        <div className="widget">
                            <form className="w-search">
                                <input className="email search input-standard-grey" required="required" placeholder="Search" type="search"/>
                                <button className="icon">
                                    <i className="seoicon-loupe"></i>
                                </button>
                            </form>
                        </div>

                        <div className="widget w-post-category">
                            <div className="heading">
                                <h4 className="heading-title">Post Category</h4>
                                <div className="heading-line">
                                    <span className="short-line"></span>
                                    <span className="long-line"></span>
                                </div>
                            </div>
                            <div className="post-category-wrap">
                                <PostCate
                                    postCount="102"
                                    postTitle="web development"
                                />
                                <PostCate
                                    postCount="102"
                                    postTitle="web development"
                                />
                                
                            </div>
                        </div>

                        <div className="widget w-latest-news">
                            <div className="heading">
                                <h4 className="heading-title">Latest News</h4>
                                <div className="heading-line">
                                    <span className="short-line"></span>
                                    <span className="long-line"></span>
                                </div>
                            </div>

                            <div className="latest-news-wrap">
                                <Latestnews
                                    newsTitle="Qemonstraverunt legere"
                                    newsDate=" March 25, 2016"
                                    newstime="12:05 pm"
                                />
                            </div>

                            <a href="14_blog.html" className="btn btn-small btn--dark btn-hover-shadow">
                                <span className="text">All News</span>
                                <i className="seoicon-right-arrow"></i>
                            </a>
                        </div>

                        <div className="widget w-follow">
                            <div className="heading">
                                <h4 className="heading-title">Follow Us</h4>
                                <div className="heading-line">
                                    <span className="short-line"></span>
                                    <span className="long-line"></span>
                                </div>
                            </div>

                            <div className="w-follow-wrap">
                                {
                                    socialicon.map((social)=>{
                                        return(
                                            <Socialsec
                                                SocialSite={social.SocialSite}
                                                SocialIcon={social.SocialIcon}
                                                colorClass={social.colorClass}
                                            />
                                        ) 
                                    })
                                }
                            </div>

                        </div>

                        <div className="widget w-tags">
                            <div className="heading">
                                <h4 className="heading-title">Popular Tags</h4>
                                <div className="heading-line">
                                    <span className="short-line"></span>
                                    <span className="long-line"></span>
                                </div>
                            </div>

                            <div className="tags-wrap">
                                <a href="#" className="w-tags-item">SEO</a>
                                <a href="#" className="w-tags-item">Advertising</a>
                                <a href="#" className="w-tags-item">Business</a>
                                <a href="#" className="w-tags-item">Optimization</a>
                                <a href="#" className="w-tags-item">Digital Marketing</a>
                                <a href="#" className="w-tags-item">Social</a>
                                <a href="#" className="w-tags-item">Keyword</a>
                                <a href="#" className="w-tags-item">Strategy</a>
                                <a href="#" className="w-tags-item">Audience</a>
                            </div>
                        </div>
                    </aside>
                </div>

            </main>
        </div>
    </div>
</>
    )
}

export default Blogdetail;