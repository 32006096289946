import {Helmet} from "react-helmet";
const Webdevlopment = ()=>{
    return(
      <>
      
     <div className="header-spacer" /> 
     <div className="content-wrapper webdevloper">
        {/* <div className="stunning-header stunning-header-bg-violet">
            <div className="stunning-header-content">
                <h1 className="stunning-header-title">Web Devlopment Services</h1>
                <ul className="breadcrumbs">
                <li className="breadcrumbs-item">
                    <a href="index.html">Home</a>
                    <i className="seoicon-right-arrow" />
                </li>
                <li className="breadcrumbs-item active">
                    <span href="#">Web Devlopment Services</span>
                    <i className="seoicon-right-arrow" />
                </li>
                </ul>
            </div>
        </div> */}
        <section className="banner">
          <div className="banner-side-img banner-img1 position-absolute view-on">
            <img src="img/ns2.png" alt="banner btn 1"/>
          </div>
          <div className="banner-side-img banner-img2 position-absolute view-on">
            <img src="img/ns111.png" alt="image"/>
          </div>
          <div className="container">
            <div className="row">
              <div className="mt-5">
                <div className="dia-banner-content dia-headline pera-content">
                  <h1 className="cd-headline clip is-full-width">We are one of the <br/>Finest IT Company for<br/>Website  Designing.
                  </h1>
                </div>
                <div className="form-bx" id="frm">
                  <div className="">
                    <h2 className="text-center frm-h">ENQUIRE NOW </h2>
                  </div>      
                  <form  method="POST">
                    <div className="row"> 
                      <div className="col-lg-6">
                        <div className="formgroup">
                          <input className="inpt-styl" required="" name="firstname" type="text" placeholder="Name"/>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="formgroup">
                          <input className="inpt-styl" required="" name="email" type="email" placeholder=" Email"/>
                        </div>
                      </div> 
                    </div>
                    <div className="row"> 
                      <div className="col-lg-6">
                        <div className="formgroup">
                          <input className="inpt-styl" required="" name="contact" type="number" placeholder="Phone"/>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="formgroup">
                          <select name="services" className="inpt-styl form-control" required="">
                            <optgroup label="" data-select2-id="">
                              <option>Select Service</option>
                              <option>Website Design &amp; Development</option>
                              <option>App Development</option>
                              <option>Ecommerce Development</option>
                              <option>Custom Softwares</option>
                              <option>Digital Marketing</option>
                            </optgroup>
                          </select>
                        </div> 
                      </div>
                    </div>
                    <div className="formgroup">
                      <textarea className="inpt-styl" required="" name="comments" id="" cols="30" rows="3" placeholder="Message"></textarea>
                    </div>
                    <div>
                      <button className="bt" type="submit">Submit</button>
                    </div>
                  </form>     
                </div>
              </div>
            </div>
          </div>
          <div className="banner-shape1 position-absolute">
            <img src="img/pls2.png" alt=""/>
          </div>
        </section>
        <section id="dia-service" className="dia-service-section  ">
          <div className="container">
              <div className="dia-service-content">
                <div className="row">
                    <div className="col-lg-12 col-lg-6">
                      <div className="dia-service-img position-relative">
                          <img src="img/ban-1.png" alt=""/>
                          <div className="dia-service-shape dia-service-shape1 position-absolute">
                            <img src="img/sss.png" alt=""/>
                          </div>
                          <div className=" dia-service-shape dia-service-shape2 position-absolute">
                            <img src="img/s3.png" alt=""/>
                          </div>
                      </div>
                    </div>
                    <div className=" col-lg-6 col-lg-6 ">
                      <div className="dia-service-text">
                          <div className="dia-section-title text-left text-capitalize dia-headline">
                            <h2>What We Do</h2>
                          </div>
                          <div className="dia-service-details clearfix">
                            <ul className="service-ul">
                                <li>
                                  <img src="img/i1.png"/><span>Web Designing &amp; Development</span>
                                </li>
                                <li>
                                  <img src="img/i5.png"/><span>Wordpress Development</span>
                                </li>
                                <li>
                                  <img src="img/i3.png"/><span>Ecommerce Development</span>
                                </li>
                                <li>
                                  <img src="img/i6.png"/><span>Software &amp; Application Development</span>
                                </li>
                            </ul>
                          </div>
                           <div className="dia-service-btn">
                            <div className="dia-service-more text-center float-left">
                                <a href="#">Let's get started</a>
                            </div>
                          </div> 
                      </div>
                    </div>
                </div>
              </div>
          </div>
        </section>
        
        <section id="" className="dia-fun-fact-section dia-service-section pt pb-2 mb-4">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                  <div className=" pbt-p text-center text-capitalize ">
                    <span className="dia-section-title h2 txt-pricing" >Pricing</span>
                  </div>
              </div>
              <div className="col-lg-4 pt-5">
                  <div className="tble">
                    <h2 className="main-heading f38">Static Website</h2>
                    <ul className="details">
                        <li><span><i className="fas fa-check"></i></span>Upto 5 Web Pages </li>
                        <li><span><i className="fas fa-check"></i></span>Banner Slider-3</li>
                        <li><span><i className="fas fa-check"></i></span>Mobile Responsive </li>
                        <li><span><i className="fas fa-check"></i></span>SEO Friendly</li>
                        <li><span><i className="fas fa-check"></i></span>Form &amp; Google Map Integration</li>
                        <li><span><i className="fas fa-check"></i></span>Social Media Link Integration</li>
                    </ul>
                    <br/>
                    <div className="dia-service-btn clearfix">
                        <div className="text-center">
                          <a className="shop-btn" href="#">Enquire Now</a>
                        </div>
                    </div>
                  </div>
              </div>
              <div className=" col-lg-4  pt-5">
                  <div className="tble">
                    <h2 className="main-heading2 f38">Dynamic Website</h2>
                    <ul className="details">
                        <li><span><i className="fas fa-check"></i></span>Upto 10 Web Pages </li>
                        <li><span><i className="fas fa-check"></i></span>Banner Slider -5</li>
                        <li><span><i className="fas fa-check"></i></span>Mobile Responsive </li>
                        <li><span><i className="fas fa-check"></i></span>SEO Friendly</li>
                        <li><span><i className="fas fa-check"></i></span>Form &amp; Google Map Integration </li>
                        <li><span><i className="fas fa-check"></i></span>Social Media Link Integration</li>
                    </ul>
                    <br/>
                    <div className="dia-service-btn clearfix">
                        <div className="text-center">
                          <a className="shop-btn2 " href="#">Enquire Now</a>
                        </div>
                    </div>
                  </div>
              </div>
              <div className=" col-lg-4 pt-5">
                  <div className="tble">
                    <h2 className="main-heading3 f38">Ecommerce </h2>
                    <ul className="details">
                        <li><span><i className="fas fa-check"></i></span>Category Based Products</li>
                        <li><span><i className="fas fa-check"></i></span>Mobile Responsive</li>
                        <li><span><i className="fas fa-check"></i></span>User Registration</li>
                        <li><span><i className="fas fa-check"></i></span>Shopping Cart &amp; Checkout</li>
                        <li><span><i className="fas fa-check"></i></span>Payment Gateway Integration</li>
                        <li><span><i className="fas fa-check"></i></span>Hosting &amp; Domain 1 year </li>
                    </ul>
                    <br/>
                    <div className="dia-service-btn clearfix">
                        <div className="text-center">
                          <a className="shop-btn3" href="#">Enquire Now</a>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </section>

      <section id="dia-about" className="dia-about-section pt pb-6" >
        <div className="container">
            <div className="dia-about-content">
              <div className="row">
                  <div className=" col-lg-6 col-md-12 wow fadeFromLeft animated aminme1" data-wow-delay="0ms" data-wow-duration="1500ms" >
                    <div className="dia-about-text-area">
                        <div className="dia-about-title-text">
                          <div className="dia-section-title text-left  dia-headline">
                              <h2 >HOW  WE DO IT</h2>
                              <p className="how-p">We Understand, Analyse, Define your competition and target audience. Discover what is new and working in your online  industry, then design your website accordingly. </p>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className=" col-lg-6  col-md-12">
                    <img src="img/img3.png"/>
                  </div>
              </div>
              <div className="row py-10">
                  <div className="col-lg-3 col-md-3 wow fadeFromRight animated aminme2" data-wow-delay="300ms" data-wow-duration="1500ms" >
                    <div className="services">
                        <div className="icon-flx">
                          <img src="img/ic5.png"/>
                        </div>
                        <h3 id="do-h1"><span>Analyse</span></h3>
                    </div>
                    <div className="line1">
                      <img src="img/arrow1.png"/>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 wow fadeFromRight animated aminme2" data-wow-delay="300ms" data-wow-duration="1500ms" >
                    <div className="services">
                        <div className="icon-flx">  
                          <img src="img/ic2.png"/>
                          </div>
                        <h3 id="do-h2"><span>Design</span></h3>
                    </div>
                    <div className="line">
                        <img src="img/flip-arrow.png"/>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 wow fadeFromRight animated aminme2" data-wow-delay="300ms" data-wow-duration="1500ms" >
                    <div className="services">
                        <div className="icon-flx">
                          <img src="img/ic3.png"/>
                        </div>
                        <h3 id="do-h3"><span>Develop</span></h3>
                    </div>
                    <div className="line1">
                        <img src="img/arrow1.png"/>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 wow fadeFromRight animated aminme2" data-wow-delay="300ms" data-wow-duration="1500ms" >
                    <div className="services">
                        <div className="icon-flx">
                          <img src="img/ic4.png"/>
                        </div>
                        <h3 id="do-h4"><span>Deliver</span></h3>
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </section>
     </div>
   </>
    );
  }
 
  export default Webdevlopment;