import {Helmet} from "react-helmet";
import {Link } from "react-router-dom";
const Graphic = ()=>{
    return(
      <>
        

        <div className="header-spacer" />
        <div className="content-wrapper">
            <div className="stunning-header stunning-header-bg-violet">
                <div className="stunning-header-content">
                    <h1 className="stunning-header-title">Graphic Design Services</h1>
                    <ul className="breadcrumbs">
                    <li className="breadcrumbs-item">
                        <Link to="/">Home</Link>
                        <i className="seoicon-right-arrow" />
                    </li>
                    <li className="breadcrumbs-item active">
                        <span href="#">Graphic Design Services</span>
                        <i className="seoicon-right-arrow" />
                    </li>
                    </ul>
                </div>
            </div>
            <div className="services-overview-area">
              <div className="container">
                <div className="row py-4"> 
                  <div className="col-xs-12 col-lg-12">
                    <h2 className="h3 service-overview-block-title text-center pb-4">Logo Design</h2>
                    <p>Your logo gives an identity to your business, enabling clients to quickly recognize your brand at whatever point they go over this particular visual mark. At Unik Art, we know the significance of a logo which makes the initial introduction of your business. We, in this manner, offer custom logo design services, went for building your image character among various different organizations from your specialty. With our logo plan, your business gets an amazing impression to mirror the uniqueness of your image. We enjoy the reputation of being an affordable logo design company that attempts to provide a business with an innovative and creative logo at an economical cost.</p>
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-xs-12 col-lg-12">
                    <h2 className="h3 service-overview-block-title text-center">Design a logo by choosing your industry</h2>
                  </div>
                </div>  
                <div className="row about-container py-4">
                  <div className="col-lg-6 content order-lg-1 order-2">
                      <div className="icon-box" id="in">
                        <div className="icon"><i className="fa fa-female"></i></div>
                      
                          <h4 className="title"><a href="#">Fashion, shopping, events</a></h4>
                          <p className="description">Fashion, vintage, style, retro, elegant, chic, clothing brand, dressing, fashion designer.</p>
                        
                      </div>

                      <div className="icon-box"  id="school" >
                        <div className="icon"><i className="fa fa-usd"></i></div>
                       
                          <h4 className="title"><a href="#">Finance, marketing, consulting</a></h4>
                          <p className="description"> Business, job, company, work, commercial, jobs, corporate, industries, organization.</p>
                        
                      </div>

                      <div className="icon-box "  id="employee" >
                        <div className="icon"><i className="fa fa-futbol-o"></i></div>
                        <div className="">
                          <h4 className="title"><a href="#">Sports, fitness, coaching</a></h4>
                          <p className="description">Sport, sports, exercise, passion, sports group, leisure, multisport, recreation, activity.</p>  
                        </div>
                      </div>

                      <div className="icon-box wow fadeInUp animated"  id="employee" >
                        <div className="icon"><i className="fa fa-medkit"></i></div>
                        <div className="">
                          <h4 className="title"><a href="#">Health, medical, doctor</a></h4>
                          <p className="description">Health, medical, healthcare, care, smoke, cigarette, health care, smoking, surgery.</p>
                        </div>
                      </div>
                  </div>
                  <div className="col-lg-6 content order-lg-1 order-2">
                      <div className="icon-box wow fadeInUp animated"  id="employee" >
                        <div className="icon"><i className="fa fa-sort-alpha-asc"></i></div>
                        <div className="">
                          <h4 className="title"><a href="#">Letters A-Z</a></h4>
                          <p className="description">Letter, letters, alphabet, graffiti, abc, alphabets, script, a to z, typography.</p>
                        </div>
                          
                      </div>
                      <div className="icon-box wow fadeInUp animated"  id="employee" >
                        <div className="icon"><i className="fa fa-suitcase"></i></div>
                        <div className="">
                          <h4 className="title"><a href="#">Travel, tourism</a></h4>
                          <p className="description">Travel, tour, tourism, travels, travel agency, holiday, tours, vacation, travelling.</p>
                        </div>
                      </div>
                      <div className="icon-box wow fadeInUp animated"  id="employee" >
                        <div className="icon"><i className="fa fa-book"></i></div>
                        <div className="">
                          <h4 className="title"><a href="#">Education, association</a></h4>
                          <p className="description"> Education, educational, learn, academic, eduction, educate, education consultant, academics.</p>
                        </div>
                      </div>
                      <div className="icon-box"  id="employee" >
                        <div className="icon"><i className="fa fa-cutlery"></i></div>
                        <div className="">
                          <h4 className="title"><a href="#">Food, drinks, restaurant</a></h4>
                          <p className="description">Food, hospitality, eat, food and drinks, food and drink, food industry, food products, food blog, food and beverage.</p>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="row about-extra mb-5" id="web">
                  <div className="col-lg-6">
                      <img src="img/digi.jpg" className="img-fluid"/>
                  </div>
                  <div className="col-lg-6 pt-5 pt-lg-0" >
                      <h4 className="pb-2">Poster design</h4>
                      <p>
                        A poster is a promotion of an idea, product, or event put up in a public space for mass consumption. Typically, posters include both textual and graphic elements, although a poster may be either wholly graphical or wholly text. Posters are designed to be both eye-catching and informative.
                      </p>
                      <p>
                        We provide the posters advertising consumer products and entertainment, but also events such as the World's Fairs and Colonial Exhibitions.
                      </p>
                  </div>
                </div>
                <div className="row about-extra">
                  
                  <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1" >
                    <h4 className="pb-2">Brochure Design</h4>
                    <p>
                    A brochure is an informative paper document (often also used for advertising) that can be folded into a template, pamphlet, or leaflet. A brochure can also be a set of related unfolded papers put into a pocket folder or packet. Brochures are promotional documents, primarily used to introduce a company, organization, products or services and inform prospective customers or members of the public of the benefits. 
                    </p>
                    <p>
                        Brochures are distributed in many different ways: as newspaper inserts, handed out personally, by mail or placed in brochure racks in high traffic locations especially in tourist precincts
                      We create brochures that target your audience.
                    </p>
                      <p> We create brochures that target your audience.</p>
                  </div> 
                  <div className="col-lg-6" >
                    <img src="img/about-extra-2.svg" className="img-fluid" />
                  </div>
                </div>
                <div className="row about-extra mb-5 py-4" id="web">
                  <div className="col-lg-6 wow fadeInUp animated" >
                    <img src="img/digi.jpg" className="img-fluid"/>
                  </div>
                  <div className="col-lg-6 wow fadeInUp pt-5 pt-lg-0 animated" >
                    <h4 className="pb-2">Menu Design</h4>
                    <p>
                    In a restaurant, the menu is a list of food and beverages offered to customers and the prices. A menu may be à la carte – which presents a list of options from which customers choose – or table d'hôte, in which case a pre-established sequence of courses is offered. Menus may be printed on paper sheets provided to the diners, put on a large poster or display board inside the establishment, displayed outside the restaurant, or put on a digital screen..
                    </p>
                    <p>
                      Our engineers study the visual and verbal psychology behind why people order certain items—and use that valuable information to design menus in a way that maximizes restaurant profits.
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </>
    );
  }
 
  export default Graphic;