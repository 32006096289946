import React, {Component} from 'react';

 class Newsletters extends Component {

    state = {
        email:'',
        emailStatus: ''
    };
    
    // handle the value
    handleCHange = input => e => {
        this.setState({[input]: e.target.value});
    }
    
    // when submit btn is clicked
    submitForm = (e) => {
        const {email} = this.state;
    
    
        // create a new XMLHttpRequest
        var xhr = new XMLHttpRequest();
    
        // get a callback when the server responds
        xhr.addEventListener('load', () => {
            // update the response state and the step
            
            this.setState ({
                emailStatus: xhr.responseText
            });
        });
        // open the request with the verb and the url
        xhr.open('GET', 'https://wla.kailashchandra.com/profile/sendemail/newsletter.php?sendto=' + email);
        // send the request
        xhr.send();
    
        // reset the fields
        this.setState({
          email:''
        });
        e.preventDefault();
    }

    render(){
        const {email,emailStatus} = this.state;
    return(
        <>
        <div className="container-fluid bg-green-color">
            <div className="row">
                <div className="container">
                <div className="row">
                    <div className="subscribe scrollme">
                    <div className="images-block col-lg-6">
                        <img src="img/subscr-gear.png" alt="gear" className="gear" />
                        <img src="img/subscr1.png" alt="mail" className="mail" />
                        <img src="img/subscr-mailopen.png" alt="mail" className="mail-2" />
                    </div>
                    <div className="col-lg-6 col-lg-offset-5 col-md-6 col-md-offset-5 col-sm-12 col-xs-12">
                        <h4 className="subscribe-title">Email Newsletters!</h4>
                        <form className="subscribe-form" method="POST" onSubmit={this.submitForm}>
                        <input value={email} onChange={this.handleCHange('email')} className="email input-standard-grey input-white" placeholder="Your Email Address" type="email" autoComplete="off" required />
                        <input type="submit" value="subscribe" className="subscr-btn" />
                        <div className="row">
                            <div className="submit-block table">
                            <div className="col-lg-3 table-cell">
                            {emailStatus ? emailStatus : null}
                            </div>
                            </div>
                        </div>
                        </form>
                        <div className="sub-title">Sign up for new Seosignt content, updates, surveys &amp; offers.</div>
                    </div>

                    </div>
                </div>
                </div>
            </div>
        </div>
        </>
    )
}
}
export default Newsletters; 