import {Helmet} from "react-helmet";
import {Link } from "react-router-dom";
const WebHost = ()=>{
    return(
      <>
         

        <div className="header-spacer" />
        <div className="content-wrapper">
            <div className="stunning-header stunning-header-bg-violet">
                <div className="stunning-header-content">
                    <h1 className="stunning-header-title">Web Hosting</h1>
                    <ul className="breadcrumbs">
                    <li className="breadcrumbs-item">
                        <Link to="/">Home</Link>
                        <i className="seoicon-right-arrow" />
                    </li>
                    <li className="breadcrumbs-item active">
                        <span href="#">Web Hosting</span>
                        <i className="seoicon-right-arrow" />
                    </li>
                    </ul>
                </div>
            </div>
            <div className="container pt-6">
              <h4 className="h1 heading-title">Web Hosting</h4>
              <div className="row pb30">
                <div className="col-lg-12">
                  <h5 className="info-box-title">What is Web Hosting?</h5>
                  <p>Web hosting is a service that allows organizations and individuals to post a website or web page onto the Internet. A web host, or web hosting service provider, is a business that provides the technologies and services needed for the website or webpage to be viewed in the Internet. Websites are hosted, or stored, on special computers called servers. When Internet users want to view your website, all they need to do is type your website address or domain into their browser. Their computer will then connect to your server and your webpages will be delivered to them through the browser.</p>
                  <p>Most hosting companies require that you own your domain in order to host with them. If you do not have a domain, the hosting companies will help you purchase one.</p>
                  <h5 className="info-box-title">Here are some features you should be expecting from your hosting provider:</h5>
                  <p><strong>Email</strong>As mentioned earlier, most hosting providers require users to have their own domain name. With a domain name (e.g. www.yourwebsite.com) and email account features provided by your hosting company, you can create domain email accounts (e.g. yourname@yourwebsite.com).</p>
                  <p><strong>FTP Access</strong>The use of FTP lets you upload files from your local computer to your web server. If you build your website using your own HTML files, you can transfer the files from your computer to the web server through FTP, allowing your website to be accessed through the internet.</p>
                  <p><strong>WordPress Support</strong>WordPress is an online website creation tool. It is a powerful blogging and website content management system, which is a convenient way to create and manage website. WordPress powers over 25% of websites on the internet. Most hosting providers will tell you right away if their plans are WordPress-compatible or not. The simple requirements for hosting your WordPress websites include: PHP version 7 or greater; MySQL version 5.6 or greater.</p>
                  <p>If you decide to create and host your website with Website.com, in addition to access to the drag and drop site builder, you can get a custom domain, email addresses, and web hosting all bundled into one subscription.

  An advantage of using a website builder is that it allows you to simply drag and drop content to create your site, and publish your site to live with a few clicks. No technical experience is required. With the Website.com site builder, you will not need to build your website with coding, and you will not need to transfer files from your desktop computer to the server through FTP.</p>
                  <h5 className="info-box-title">How can my online business benefit from a web hosting service?</h5>
                  <p>In order to publish your website online, your business website requires a web hosting service. However, a web host gives business owners more than just web hosting services! For example, web hosting firms typically employ in-house technicians to make sure their clients' websites are up and running 24/7. Plus, when website owners are in need of help or troubleshooting (e.g. script debutting, email not able to send/receive, domain name renewal, and more), the web host's in-house support are the go-to people. A professional web hosting service ensures a hassle-free experience for business owners, so they can efficiently focus their time and effort on their businesses.</p>
                </div>
              </div>
            </div>
        </div>
      </>
    );
  }
 
  export default WebHost;