import { Link } from "react-router-dom";

const Article = (props)=>{
    
    const {postid,postDate,postDetail,postTitle} = props;

    return(
        <>
            <article className="hentry post post-standard has-post-thumbnail sticky">
                <div className="post-thumb">
                    <img src='https://pluxinfosystems.com/img/blog.jpeg' alt="seo"/>
                    
                    <div className="overlay"></div>
                    <a href="img/blog.jpeg" className="link-image js-zoom-image">
                        <i className="seoicon-zoom"></i>
                    </a>
                    <a href="#" className="link-post">
                        <i className="seoicon-link-bold"></i>
                    </a>
                </div>
                <div className="post__content">
                    {/* <div className="post__author author vcard">
                        <img src={authorPic} alt="author"/>
                        
                        {author}
                        <div className="post__author-name fn">
                            <a href="#" className="post__author-link">{authorName}</a>
                        </div>
                    </div> */}
                    <div className="post__content-info">
                        <h2 className="post__title entry-title ">
                            <a href="">{postTitle}</a>
                        </h2>
                        <div className="post-additional-info">
                            <span className="post__date">
                                <i className="seoicon-clock"></i>

                                <time className="published">
                                    {postDate}
                                </time>
                            </span>
                            {/* <span className="category">
                                <i className="seoicon-tags"></i>
                                <a href="#">Video</a>
                            </span>
                            <span className="post__comments">
                                <a href="#"><i className="fa fa-comment-o" aria-hidden="true"></i></a>
                                6
                            </span> */}
                        </div>
                        <p className="post__text" dangerouslySetInnerHTML={{__html: postDetail}}></p>
                        {/* <a href="" className="btn btn-small btn--dark btn-hover-shadow">
                            <span className="text">Continue Reading</span>
                            <i className="seoicon-right-arrow"></i>
                        </a> */}
                        <Link to={`/blogs/${postid}`} className="btn btn-small btn--dark btn-hover-shadow">
                            <span className="text">Continue Reading</span>
                            <i className="seoicon-right-arrow"></i>
                        </Link>
                    </div>
                </div>
            </article>
        </>
    )
}
export default Article; 