import React from 'react';
import Footer from '../Component/Footer';
import Header from '../Component/Header';
import {Helmet} from "react-helmet";
import Newsletters from './Newsletters';
import {Link } from "react-router-dom";

const About=()=>{
  const pscsstyle = {
    backgroundColor:"#060607",
  }
  
 
    return(
        <>
<div>
<div className="header-spacer" />
  <div className="content-wrapper">
    {/* Stunning header */}
    <div className="stunning-header stunning-header-bg-lightblue">
      <div className="stunning-header-content">
        <h1 className="stunning-header-title">About Our Company</h1>
        <ul className="breadcrumbs">
          <li className="breadcrumbs-item">
            <Link to="/home">Home</Link>
            <i className="seoicon-right-arrow" />
          </li>
          <li className="breadcrumbs-item active">
            <span>About Our Company</span>
            <i className="seoicon-right-arrow" />
          </li>
        </ul>
      </div>
    </div>
    {/* End Stunning header */}
    {/*  Overlay Search */}
    <div className="overlay_search">
      <div className="container">
        <div className="row">
          <div className="form_search-wrap">
            <form>
              <input className="overlay_search-input" placeholder="Type and hit Enter..." type="text" />
              <a href="#" className="overlay_search-close">
                <span />
                <span />
              </a>
            </form>
          </div>
        </div>
      </div>
    </div>
    {/* End Overlay Search */}
    <div className="container">
      <div className="row mt-6">
        <div className="col-lg-12">
          <div className="heading mb-0">
            <h4 className="h1 heading-title">Short Story About Our Company</h4>
            <div className="heading-line">
              <span className="short-line" />
              <span className="long-line" />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <p>Plux Infosystems is an India based web designing, Internet marketing, and mobile application development company with a unique approach towards the web. For some of our customers, we are an internet marketing company who can help their website rank better in Google, to others we are web developers while to some others we are the people who can create applications they want to see the build. We can’t get enough of web and aspire to help each of our clients do more with it.We welcome the challenges that every project presents. Our skilled team of professionals is continuously utilizing the best of their experience to keep pace with the ever-changing demands of client and technology.
          </p>
        </div>
        <div className="col-lg-6">
          <p> Our ability to provide the highest level of customized solutions are even known to exceed the highest expectations of our clients. Throughout the globe, we have received utmost appreciation and recognition owing to our strong business ethics and client-friendly approach. Because when it comes to success and long-term goals, it can only be built on the strong foundation of long-standing partnerships. Therefore, as a client of WebRiders India, you can assure that your goals will be our topmost priority.</p>
        </div>
      </div>
    </div>
    {/* Time line */}
  
    {/* Our vision */}
    <div className="container-fluid">
      <div className="row bg-orangedark-color">
        <div className="our-vision scrollme">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-12 col-xs-12">
                <div className="heading align-center mb-0">
                  <h4 className="h1 heading-title">Our Approach</h4>
                  <div className="heading-line">
                    <span className="short-line bg-yellow-color" />
                    <span className="long-line bg-yellow-color" />
                  </div>
                  <p className="heading-text c-white">We approach every project like it’s a masterpiece. Big or small, each website is hand-crafted to suit the unique demands of the client. This ensures all our projects are as unique. Our process of working is simple: Research, Create, Deliver
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img src="img/elements.png" className="elements" alt="elements" />
          <img src="img/eye.png" className="eye" alt="eye" />
        </div>
      </div>
    </div>
    {/* End Our vision */}
    {/* Testimonial slider */}
    <div className="container-fluid">
      <div className="row pt-6 pb-6 bg-border-color">
        <div className="container">
          <div className="testimonial-arrow-slider">
            <div className="row">
              <div className="col-lg-12">
                <div className="heading mb-0">
                  <h4 className="h1 heading-title">Our Customers Say</h4>
                  {/* <a href="#" className="read-more">Read All Testimonial
                    <i className="seoicon-right-arrow" />
                  </a> */}
                  <div className="heading-line">
                    <span className="short-line" />
                    <span className="long-line" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="swiper-container pagination-bottom">
                <div className="swiper-wrapper">
                  <div className="case-slider-item swiper-slide">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="testimonial-item testimonial-arrow mb30">
                        <div className="testimonial-text">
                        "Kailash has worked with me on various projects and has always exceeded my expectations. Work always completed in a timely manner. Professional work, and very communicative. I highly recommend Kailash for your future project work."
                        </div>
                        <div className="author-info-wrap table">
                          <div className="testimonial-img-author table-cell">
                            <img src="img/avatar.png" alt="author" />
                          </div>
                          <div className="author-info table-cell">
                            <h6 className="author-name">RICHARD D.</h6>
                            <div className="author-company c-primary">tipster challenge</div>
                          </div>
                        </div>
                        <div className="quote">
                          <i className="seoicon-quotes" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="testimonial-item testimonial-arrow mb30">
                        <div className="testimonial-text">
                        "Kailash has demonstrated extraordinary brilliance while working with eVanik Networks. His knack for understanding the problem and solving it with out of the box thinking is what is really expected out of great engineers. I wish him all the best in his future endeavours"
                        </div>
                        <div className="author-info-wrap table">
                          <div className="testimonial-img-author table-cell">
                            <img src="img/avatar.png" alt="author" />
                          </div>
                          <div className="author-info table-cell">
                            <h6 className="author-name">Mayank Kumar</h6>
                            <div className="author-company c-primary">eVanik Networks</div>
                          </div>
                        </div>
                        <div className="quote">
                          <i className="seoicon-quotes" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="case-slider-item swiper-slide">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="testimonial-item testimonial-arrow mb30">
                        <div className="testimonial-text">
                        Investigations have shown that readers read me more than they read.
                        </div>
                        <div className="author-info-wrap table">
                          <div className="testimonial-img-author table-cell">
                            <img src="img/avatar.png" alt="author" />
                          </div>
                          <div className="author-info table-cell">
                            <h6 className="author-name">Jonathan Simpson</h6>
                            <div className="author-company c-primary">Envato Market</div>
                          </div>
                        </div>
                        <div className="quote">
                          <i className="seoicon-quotes" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="testimonial-item testimonial-arrow mb30">
                        <div className="testimonial-text">
                        For a free time when we are free, we teenagers have no choice
                        </div>
                        <div className="author-info-wrap table">
                          <div className="testimonial-img-author table-cell">
                            <img src="img/avatar3.png" alt="author" />
                          </div>
                          <div className="author-info table-cell">
                            <h6 className="author-name">Angelina Johnson</h6>
                            <div className="author-company c-primary">Codecanyon</div>
                          </div>
                        </div>
                        <div className="quote">
                          <i className="seoicon-quotes" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="case-slider-item swiper-slide">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="testimonial-item testimonial-arrow mb30">
                        <div className="testimonial-text">
                        Investigations have shown that readers read me more than they read.
                        </div>
                        <div className="author-info-wrap table">
                          <div className="testimonial-img-author table-cell">
                            <img src="img/avatar.png" alt="author" />
                          </div>
                          <div className="author-info table-cell">
                            <h6 className="author-name">Jonathan Simpson</h6>
                            <div className="author-company c-primary">Envato Market</div>
                          </div>
                        </div>
                        <div className="quote">
                          <i className="seoicon-quotes" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="testimonial-item testimonial-arrow mb30">
                        <div className="testimonial-text">
                        For a free time when we are free, we teenagers have no choice
                        </div>
                        <div className="author-info-wrap table">
                          <div className="testimonial-img-author table-cell">
                            <img src="img/avatar3.png" alt="author" />
                          </div>
                          <div className="author-info table-cell">
                            <h6 className="author-name">Angelina Johnson</h6>
                            <div className="author-company c-primary">Codecanyon</div>
                          </div>
                        </div>
                        <div className="quote">
                          <i className="seoicon-quotes" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="case-slider-item swiper-slide">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="testimonial-item testimonial-arrow mb30">
                        <div className="testimonial-text">
                        Investigations have shown that readers read me more than they read.
                        </div>
                        <div className="author-info-wrap table">
                          <div className="testimonial-img-author table-cell">
                            <img src="img/avatar.png" alt="author" />
                          </div>
                          <div className="author-info table-cell">
                            <h6 className="author-name">Jonathan Simpson</h6>
                            <div className="author-company c-primary">Envato Market</div>
                          </div>
                        </div>
                        <div className="quote">
                          <i className="seoicon-quotes" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="testimonial-item testimonial-arrow mb30">
                        <div className="testimonial-text">
                        For a free time when we are free, we teenagers have no choice
                        </div>
                        <div className="author-info-wrap table">
                          <div className="testimonial-img-author table-cell">
                            <img src="img/avatar3.png" alt="author" />
                          </div>
                          <div className="author-info table-cell">
                            <h6 className="author-name">Angelina Johnson</h6>
                            <div className="author-company c-primary">Codecanyon</div>
                          </div>
                        </div>
                        <div className="quote">
                          <i className="seoicon-quotes" />
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                {/* If we need pagination */}
                <div className="swiper-pagination" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Testimonial slider */}
    {/* Clients */}
    <div className="container">
      <div className="row pt-6 pb-6">
        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <div className="heading mb-0">
            <h4 className="h1 heading-title">Our Valuable Clients</h4>
            <div className="heading-line">
              <span className="short-line" />
              <span className="long-line" />
            </div>
            <p className="heading-text">It is wonderful to note that the Gothic letter, which we now think a little clear, preferred the literary forms of the humanities during the fourteenth and fifteenth centuries
            </p>
          </div>
          {/* <a href="09_our_clients.html" className="btn btn-medium btn--dark mb30">
            <span className="text">View all Customers</span>
            <span className="semicircle" />
          </a> */}
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <div className="row">
            <div className="clients-item-wrap">
              <div className="client-item mb60 our_client_item">
                <a href="https://web.kailashchandra.com/tosall_client/" className="client-image">
                <img src="img/tosall-logo-plux.png" alt="logo" className="hover" />
                </a>
              </div>
              <div className="client-item mb60 our_client_item">
                <a href="https://tipsterchallenge.com/pluxinfo_2021/" className="client-image">
                <img src="img/logo-tipster.jpg" alt="logo" className="hover" />
                </a>
              </div>
              <div className="client-item mb60 our_client_item">
                <a href="https://www.wecharge.co.in/" className="client-image">
                <img src="img/logo-wechage.png" alt="logo" className="hover" />
                </a>
              </div>
              <div className="client-item mb60 our_client_item">
                <a href="https://web.kailashchandra.com/pscs/front/" className="client-image">
                <img src="img/logo-pscs.png" alt="logo" className="hover" style={pscsstyle}/>
                </a>
              </div>
			  <div className="client-item mb60 our_client_item">
                <a href="http://surveys.authenticopinion.com/clientpanel" className="client-image">
                <img src="img/logo-trueopinion.png" alt="logo" className="hover" />
                </a>
              </div>
			  <div className="client-item mb60 our_client_item">
                <a href="http://web.kailashchandra.com/cashewprotect/" className="client-image">
                <img src="img/logo-cashew.png" alt="logo" className="hover" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Clients */}
    {/* Subscribe Form */}
    <Newsletters />  
    {/* End Subscribe Form */}
  </div>
</div>



        </>
    )
}
export default About;