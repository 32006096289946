import { useState, useEffect } from "react";
import axios from 'axios';
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "react-share";
const Blogarticle = (props)=>{

    const {postDate,postDetail,postTitle} = props;
      return (
        <>
                   <article className="hentry post post-standard-details">

                    <div className="post-thumb">
                        <img src="https://pluxinfosystems.com/img/blog.jpeg" alt="seo"/>
                    </div>

                    <div className="post__content">
                        <h2 className="h2 post__title entry-title" dangerouslySetInnerHTML={{__html: postTitle}}></h2>


                        <div className="post-additional-info">

                            <div className="post__author author vcard">
                                <img src="https://pluxinfosystems.com/img/avatar6.png" alt="author"/>
                                Posted by

                                <div className="post__author-name fn">
                                    <a href="#" className="post__author-link">Admin</a>
                                </div>

                            </div>

                            <span className="post__date">

                            <i className="seoicon-clock"></i>

                            <time className="published">
                                {postDate}
                            </time>

                        </span>

                            {/* <span className="category">
                            <i className="seoicon-tags"></i>
                            <a href="#">Business,</a>
                            <a href="#">Seo</a>
                        </span>

                            <span className="post__comments">
                            <a href="#"><i className="fa fa-comment-o" aria-hidden="true"></i></a>
                            6
                        </span> */}

                        </div>

                        <div className="post__content-info">
                        <div className="post__text" dangerouslySetInnerHTML={{__html: postDetail}}></div>
                            {/* <div></div>
                            <p className="post__subtitle">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                                euismod tincidunt ut laoreet dolore.
                            </p>

                            <p className="post__text">Investigationes demonstraverunt lectores legere me lius quod ii legunt
                                saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium
                                lectorum. Mirum
                                <span className="c-primary">est notare quam littera gothica</span>, quam nunc putamus parum claram,
                                anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima
                                eodem modo typii quam nunc putamus parum claram, anteposuerit.
                            </p>

                            <div className="testimonial-item quote-left">

                                <h5 className="h5 testimonial-text">
                                    Mirum est notare quam littera gothica, quam nunc putamus parum claram,
                                    anteposuerit litterarum formas humanitatis placerat facer possim assum.
                                </h5>

                                <div className="author-info-wrap table">
                                    <div className="author-info table-cell">
                                        <h6 className="author-name c-primary">Angelina Johnson</h6>
                                        <div className="author-company">Envato Market</div>
                                    </div>
                                </div>

                                <div className="quote">
                                    <i className="seoicon-quotes"></i>
                                </div>

                            </div>

                            <p className="post__text">Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper
                                suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure
                                dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu
                                feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit
                                praesent luptatum quam nunc putamus parum claram, anteposuerit litterarum formas.
                            </p>

                            <h4 className="mb30">Qum Soluta Nobis Eleifend</h4>

                            <p className="post__text">Iriure dolor in hendrerit in vulputate velit esse molestie consequat,
                                vel illum dolore eu feugiat <span className="c-dark">nulla facilisis at vero eros</span>
                                et accumsan et iusto odio dignissim qui blandit praesent luptatum quam nunc putamus parum claram.
                            </p>

                            <ul className="list list--secondary">
                                <li>
                                    <i className="seoicon-check"></i>
                                    <a href="#">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                                        nonummy nibh <span className="c-primary">euismod tincidunt;</span>
                                    </a>
                                </li>
                                <li>
                                    <i className="seoicon-check"></i>
                                    <a href="#">Mirum est notare quam littera gothica;</a>
                                </li>
                                <li>
                                    <i className="seoicon-check"></i>
                                    <a href="#">Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse
                                        molestie consequat, vel illum dolore eu feugiat nulla;
                                    </a>
                                </li>
                                <li>
                                    <i className="seoicon-check"></i>
                                    <a href="#">Investigationes demonstraverunt lectores.</a>
                                </li>
                            </ul>

                            <p className="post__text">Quis autem vel eum iriure dolor in hendrerit in vulputate velit esse
                                molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan
                                et iusto odio dignissim qui blandit praesent quam nunc putamus parum claram.
                            </p>

                            <div className="widget w-tags">
                                <div className="tags-wrap">
                                    <a href="#" className="w-tags-item">SEO</a>
                                    <a href="#" className="w-tags-item">Advertising</a>
                                    <a href="#" className="w-tags-item">Business</a>
                                    <a href="#" className="w-tags-item">Optimization</a>
                                </div>
                            </div>  */}

                        </div>
                    </div>

                    <div className="socials" style={{display:'flex'}}>
                    <span>Share:</span>&nbsp;
                    <FacebookIcon size={32} round={true} />&nbsp;&nbsp;

                    <TwitterIcon size={32} round={true} />&nbsp;&nbsp;

                    <LinkedinIcon size={32} round={true} />&nbsp;&nbsp;
                    
                    <WhatsappIcon size={32} round={true} />
                        {/* <a href="" className="social__item">
                            <i className="seoicon-social-facebook"></i>
                        </a>
                        <a href="" className="social__item">
                            <i className="seoicon-social-twitter"></i>
                        </a>
                        <a href="" className="social__item">
                            <i className="seoicon-social-linkedin"></i>
                        </a>
                        <a href="" className="social__item">
                            <i className="seoicon-social-google-plus"></i>
                        </a>
                        <a href="" className="social__item">
                            <i className="seoicon-social-pinterest"></i>
                        </a> */}
                    </div>

                    </article>
        </>
      );
}

export default Blogarticle;