import {Helmet} from "react-helmet";
import {Link } from "react-router-dom";
const webdesign = ()=>{
    return(
      <>
         

        <div className="header-spacer" />
        <div className="content-wrapper">
            <div className="stunning-header stunning-header-bg-violet">
                <div className="stunning-header-content">
                    <h1 className="stunning-header-title">Web Design Services</h1>
                    <ul className="breadcrumbs">
                    <li className="breadcrumbs-item">
                        <Link to="/">Home</Link>
                        <i className="seoicon-right-arrow" />
                    </li>
                    <li className="breadcrumbs-item active">
                        <span href="#">Web Design Services</span>
                        <i className="seoicon-right-arrow" />
                    </li>
                    </ul>
                </div>
            </div>
            <div className="services-overview-area">
              <div className="container">
                  <div className="row row-flex">
                    <div className="col-xs-12 col-lg-6">
                      <section className="service-overview-block p-2">
                        <div className="d-flex design_serv_flex flex-wrap ">
                          <div className="w-25 service-overview-block-icon icon text-center icon-third-party-website-maintenance" style={{color: "#5c6db1"}}>
                            <img src="img/webdesignimg-1.png" alt="image"/>
                          </div>
                          <div className="w-75">
                            <h2 className="h3 service-overview-block-title text-center">Do’s of Web Design</h2>
                           
                            <div className="service-overview-block-footer">
                              <ul className="list-inline service-overview-block-list">
                                <li>1. Keep your interface consistent</li>
                                <li>2. Design easy-to-use navigation </li>
                                <li>3. Change the color of visited links </li>
                                <li>4. Make it easy to scan your pages </li>
                                <li>5. Take content seriously </li>
                                <li>6. Check your website for errors </li>
                                <li>7. Minimize the number of choices </li>
                                <li>8. Engage users to scroll </li>
                                <li>9. Label buttons according to what they do</li>
                                <li>10. Make things look like they work</li>
                                <li>11. Make your website responsive</li>
                                <li>12. Test your design</li>
                              </ul>
                            </div>
                          </div>
                        </div>  
                      </section>
                    </div>
                    <div className="col-xs-12 col-lg-6">
                      <section className="service-overview-block p-2">
                        <div className="d-flex design_serv_flex flex-wrap">
                          <div className="w-25 service-overview-block-icon icon text-center icon-third-party-website-maintenance" style={{color: "#5c6db1"}}>
                            <img src="img/webdesignimg-2.png" alt="image"/>
                          </div>
                          <div className="w-75">
                            <h2 className="h3 service-overview-block-title text-center">Don’ts of Web Design</h2>
                            
                            <div className="service-overview-block-footer">
                              <ul className="list-inline service-overview-block-list">
                                <li>1. Don’t make users wait for content to load</li>
                                <li>2. Don’t open internal link in new tabs</li>
                                <li>3. Don’t use too many typefaces</li>
                                <li>4. Don’t use too many colors on your website</li>
                                <li>5. Don’t show automatic pop-ups too early</li>
                                <li>6. Don’t use generic photos of people</li>
                                <li>7. Don’t let promotion steal the show </li>
                                <li>8. Don’t play background music or autoplay videos with music </li>
                                <li>9. Don’t hijack scrolling</li>
                                <li>10. Don’t use horizontal scroll</li>
                                <li>11. Don’t sacrifice usability for the sake of beauty</li>
                                <li>12. Use blinking text and ads</li>
                              </ul>
                            </div>
                          </div>
                        </div>  
                      </section>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-lg-6 what_do">

                      <h5>1. Keep your interface consistent</h5>
                      <p>One of the top principles of good UX is to keep the interface consistent throughout the entire product. The overall look and feel of your website should be consistent across all of your site’s pages. Consistency of navigation, color schemes, typefaces, and style of writing can have a positive impact on usability and UX. <br/><br/>
                      <strong>Practical tip:</strong> Make design usable first. Consistency is a double-edged sword. If your website isn’t designed correctly at first, then to make other parts of it consistent will result in consistently poor design. Thus, make design usable first, then make it consistent.</p>
                      <h5>2. Design easy-to-use navigation </h5>
                      <p>Navigation is the cornerstone of usability. It’s the main interaction technique on the Internet. Having good website navigation is crucial for ensuring that visitors can find what they’re looking for.</p>
                      <h5>3. Change the color of visited links</h5>
                      <p>Links play a vital role in the navigation process. When visited links don’t change color, users could unintentionally revisit the same pages repeatedly. Knowing your past and present locations makes it easier to decide where to go next.</p>
                      <h5>4. Make it easy to scan your pages </h5>
                      <p>When users visit your site they are more likely to quickly scan the page rather than read everything on it. For example, when visitors want to find specific content or complete a certain task, they will scan a website’s pages until they find what they are looking for. And you, as a designer, can help them with that by designing good visual hierarchy. Visual hierarchy refers to the arrangement or presentation of elements in a way that implies importance</p>
                      <h5>5. Take content seriously </h5>
                      <p>Copy is just as important as the design of your website. More than 95 percent of information on the web is in the form of written language. Even if your site is beautifully designed, it’s no more than an empty frame without good content; a good website has both great design and great content. A designer’s job is to make sure that the design aids and complements the content.</p>
                      <h5>6. Check your website for errors</h5>
                      <p>A great piece of work can be easily tarnished by a small error. Here are a few common problems to be aware of:</p>
                      <ul>
                        <li>Watch out for dead links. A user can easily become frustrated when they click a link on a site and receive a 404 error page in response.</li>
                        <li>Check your website for typos.</li>
                        <li>Make sure all media content is loading correctly ━ no broken images or videos</li>
                      </ul>
                      <h5>7. Minimize the number of choices </h5>
                      <p>The number of choices people have affects their decisions; the more choices they have, the less action they take. When you present your user with too many options, you make them think too much. To increase chances for interaction, it’s better to minimize the number of choices.</p>
                      <h5>8. Engage users to scroll</h5>
                      <p>Scrolling sends users deeper into the page and makes them invest more time in the experience, and this increases the chance that users will convert ━ e.g. buy something, sign up for a newsletter, or contact you. Despite that people usually start scrolling as soon as the page loads, content at the top of the page is still very important. What appears at the top sets the impression and expectation of quality for visitors. People do scroll, but only if what’s above the fold is promising enough. <br/><br/><strong>Practical tip: Content at the top of the page sets initial expectations. If a page provides users with high-quality content, they are willing to browse for more content.</strong></p>
                      <h5>9. Label buttons according to what they do</h5>
                      <p>The label on any actionable interface element should always tie back to what it will do for the user. Users will feel more comfortable if they understand what action a button triggers. Vague labels such as “Submit” or abstract labels like in the example below don’t provide enough information about the action.</p>
                      <h5>10. Make things look like they work</h5>
                      <p>With buttons and other interactive elements, think about how the design communicates affordance. Remember the old adage “form follows function”: the way an object looks tells users how to use it. Visual elements that look like links or buttons, but aren’t clickable ━ such as underlined words that aren’t links, or elements that have a rectangular background but aren’t buttons ━ can easily confuse users</p>
                      <h5>11. Make your website responsive</h5>
                      <p>Today there are more than 5 billion devices running web browsers. This means that visitors can come to your site from various devices such as a desktop, tablet, phone, music player, or even a watch. A big part of UX design is ensuring that no matter how the visitor sees your site, all vital information will be displayed correctly on different screen sizes.</p>
                      <h5>12. Test your design</h5>
                      <p>You may have a design that you think is fantastic, but without feedback from real users, you’ll never know how effective it is. You need to see how users react to it. Even if you can get one or two real unbiased users to interact with your website and share their thoughts with you, it will provide you with many helpful insights that you wouldn’t otherwise have. <br/><br/><strong>Practical tip:</strong>  Keep an eye on analytics. Web analytics are powerful tools that can help you find areas on your website that require additional attention.</p>
                    </div>
                    <div className="col-xs-12 col-lg-6 what_notdo">
                      <h5>1. Don’t make users wait for content to load</h5>
                      <p>Loading time is extremely important for user experience. As technology progresses, we get more impatient, and today, 47 percent of users expect a web page to load in two seconds or less. If a web page takes more time to load, visitors might become frustrated and leave the site. That’s why speed should be a priority when building a web application.</p>
                      <h5>2. Don’t open internal link in new tabs</h5>
                      <p>Users expect different behavior from internal and external links. All internal links should open in the same tab; this way, you’ll allow users to use the “back” button. If you decide to open external links in a new window, you should provide an advanced warning before automatically opening a new window or tab. This might take the form of text added to the link text stating. “opens in a new window”.</p>
                      <h5>3. Don’t use too many typefaces</h5>
                      <p>When you start building a site, it’s always tempting to use a lot of different typefaces ━ five or six different fonts or even upload your own. But, it’s better to avoid that temptation. Too many variations in font types can be distracting, confusing, and borderline annoying.

                      A common recommendation is to use a maximum of three different typefaces in a maximum of three different sizes. When designing a website think about how can you make the typography powerful by playing with weight, not different fonts.</p>
                      <h5>4. Don’t use too many colors on your website</h5>
                      <p>Similar to typefaces, it’s better to avoid using too many colors in design. Applying color to a design has a lot to do with balance, and, the more colors you use, the harder it is to achieve balance. Using too many colors in design is like trying to convey a million feelings and messages at once, which can confuse the person viewing your design.<br/>It’s always better to keep the color scheme limited to a few colors and keep it consistent across your site, unless you want to highlight some important section using color.</p>
                      <h5>5. Don’t show automatic pop-ups too early</h5>
                      <p>A lot of websites show pop-up boxes with a request to subscribe as soon as you arrive on the page. As a designer, showing pop-up windows is probably one of the most annoying things you can do to someone visiting your website. Pop-ups are interruptive by nature, and, since they are typically used to show ads, users often close them even before reading the content.</p>
                      <h5>6. Don’t use generic photos of people</h5>
                      <p>Images with human faces are a very effective way to get your users engaged. Our brains are predisposed to pay attention to faces. When we see faces of other humans it makes us feel like we are actually connecting with them, and not just using a product. However, many corporate sites are notorious for their over-use of insincere photography, which is employed to “build trust.” Usability tests show that purely decorative photos rarely add value to the design and often harm the user experience.</p>
                      <h5>7. Don’t let promotion steal the show</h5>
                      <p>Having too many promotions or ads on a page can easily overshadow the main content and make it harder for users to accomplish tasks. If you have too many ads on the page, they’ll all be competing to get your readers to look at them. This will result in a sensory overload that will ultimately increase your bounce rates. It’s also important to acknowledge that anything that looks like an advertisement is usually ignored by users ━ the phenomenon is known as banner blindness.</p>
                      <h5>8. Don’t play background music or autoplay videos with music</h5>
                      <p>While background music might work in specific cases, like a promo website, it is simply a bad idea for most websites. Unexpected music or sound can annoy and potentially cause problems ━ people might be visiting your site at work, in a public place, or near someone who’s sleeping, and unexpected music could send such visitors away in an instant.<br/>Similar to background music, autoplay videos incorporated into a block of content also irritate users. They should be used sparingly and only when appropriate and expected.</p>
                      <h5>9. Don’t hijack scrolling</h5>
                      <p>Scroll hijacking is when designers manipulate the scrollbar to behave differently on their website. Hijacked scrolling is very annoying for many users since it takes control away from them and makes scrolling behavior completely unpredictable. When you design a website, it’s better to avoid scroll hijacking and let the user control their browsing and movement through the site.</p>
                      <h5>10. Don’t use horizontal scroll</h5>
                      <p>In order to stand out, some designers use horizontal scrolling on their sites. Unfortunately, horizontal scrolling is one of the few interactions that consistently generates negative responses from users. Users often have no idea they can discover content by scrolling horizontally — most of the users are accustomed to scrolling down on websites and don’t usually look left and right. As a result, they simply ignore content accessible through horizontal scrolling.</p>
                      <h5>11. Don’t sacrifice usability for the sake of beauty</h5>
                      <p>No matter how beautiful a design is, it should never interfere with a user’s ability to consume the content or interact with a website. One typical example of design decisions that often create terrible UX for the sake of beauty is using light grey text on light backgrounds. This combination affects the content’s readability. It’s better to avoid having busy backgrounds behind content or insufficient color contrast, like in the example below.</p>
                      <h5>12. Use blinking text and ads</h5>
                      <p>When creating ads and animations, don’t even consider using flickering flashing effects. Content that flashes or flickers can trigger seizures in susceptible individuals, and is likely to be annoying or distracting for regular users.</p>
                     
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </>
    );
  }
 
  export default webdesign;