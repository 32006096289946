import React, {Component} from 'react';
import Footer from '../Component/Footer';
import Header from '../Component/Header';
import Newsletters from './Newsletters';
import {Helmet} from "react-helmet";
import {Link } from "react-router-dom";

class Getintouch extends Component {

  state = {
    websiteurl:'',
    name: '',
    email: '',
    phone: '',
    details: '',
    emailStatus: ''
};

// handle the value
handleCHange = input => e => {
    this.setState({[input]: e.target.value});
}

// when submit btn is clicked
submitForm = (e) => {
    const {websiteurl, name, email, phone, details} = this.state;


    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();

    // get a callback when the server responds
    xhr.addEventListener('load', () => {
        // update the response state and the step
        
        this.setState ({
            emailStatus: xhr.responseText
        });
    });
    // open the request with the verb and the url
    xhr.open('GET', 'https://wla.kailashchandra.com/profile/sendemail/index.php?sendto=' + email + 
                            '&websiteurl=' + websiteurl + 
                            '&name=' + name + 
                            '&phone=' + phone +
                            '&details=' + details);
    // send the request
    xhr.send();

    // reset the fields
    this.setState({
      websiteurl:'',
      name: '',
      email: '',
      phone: '',
      details: ''
    });
    e.preventDefault();
}

render(){
  const {websiteurl, name, email, phone, details, emailStatus} = this.state;
    return(

        <>
<div>
  <div className="header-spacer" />
  {/* ... End Header */}
  <div className="content-wrapper">
    {/* Stunning header */}
    <div className="stunning-header stunning-header-bg-blue">
      <div className="stunning-header-content">
        <h1 className="stunning-header-title">Contact Information</h1>
        <ul className="breadcrumbs">
          <li className="breadcrumbs-item">
            <Link to="/">Home</Link>
            <i className="seoicon-right-arrow" />
          </li>
          <li className="breadcrumbs-item active">
            <span>Contact Information</span>
            <i className="seoicon-right-arrow" />
          </li>
        </ul>
      </div>
    </div>
    {/* End Stunning header */}
    {/* Overlay Search */}
    <div className="overlay_search">
      <div className="container">
        <div className="row">
          <div className="form_search-wrap">
            <form>
              <input className="overlay_search-input" placeholder="Type and hit Enter..." type="text" />
              <a href="#" className="overlay_search-close">
                <span />
                <span />
              </a>
            </form>
          </div>
        </div>
      </div>
    </div>
    {/* End Overlay Search */}
    <div className="container">
      <div className="row pt-6">
        <div className="col-lg-12">
          <div className="heading">
            <h4 className="h1 heading-title">Get In Touch</h4>
            <div className="heading-line">
              <span className="short-line" />
              <span className="long-line" />
            </div>
            <p className="heading-text">We use cookies to personalize content and ads, provide social media features, improve our site and analyze our traffic. We may share information about your use of our site with our advertising, social and analytics providers. By clicking “Accept All Cookies” you agree to the use of these cookies as further described
            </p>
          </div>
        </div>
      </div>
    </div>
    {/* Contacts */}
    <div className="container-fluid">
      <div className="row medium-padding80 bg-border-color contacts-shadow">
        <div className="container">
          <div className="row">
            <div className="contacts">
              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <div className="contacts-item">
                  <img src="img/contact7.png" alt="phone" />
                  <div className="content">
                    <Link to="" className="title">Plux Infosystems Pvt. Ltd.</Link>
                    <p className="sub-title">2nd Floor, A- 81, Block A, Sector 65, Noida - 201301</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <div className="contacts-item">
                  <img src="img/contact8.png" alt="phone" />
                  <div className="content">
                    <a href="mailto:info@pluxinfosystems.com" className="title">info@pluxinfosystems.com</a>
                    <p className="sub-title">online support</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <div className="contacts-item">
                  <img src="img/contact9.png" alt="phone" />
                  <div className="content">
                    <a href="tel:8287876959" className="title">+91 8287876959</a>
                    <p className="sub-title">Mon-Sat 9am-6pm</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Contacts */}
    {/* Google map */}
    <div className="section">
      <div id="map" />
    </div>
    {/* End Google map */}
    {/* Contact form */}
    <div className="container">
      <div className="contact-form pt-6 pb-6">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="heading">
              <h4 className="heading-title">Have You Any Questions?</h4>
              <div className="heading-line">
                <span className="short-line" />
                <span className="long-line" />
              </div>
              <p className="heading-text">Please contact us using the form and we’ll get back to you as soon as possible.</p>
            </div>
          </div>
        </div>
        <form className="contact-form" method="POST" onSubmit={this.submitForm}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <input value={websiteurl} onChange={this.handleCHange('websiteurl')} className="email input-standard-grey" placeholder="Website URL" type="text" autoComplete="off" required  />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <input value={name} onChange={this.handleCHange('name')} className="email input-standard-grey" placeholder="Your Name" type="text" autoComplete="off" required />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <input value={email} onChange={this.handleCHange('email')} className="email input-standard-grey" placeholder="Email Address" type="email" autoComplete="off" required />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <input value={phone} onChange={this.handleCHange('phone')} className="email input-standard-grey" placeholder="Phone" type="text" autoComplete="off" required/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <textarea value={details} onChange={this.handleCHange('details')} className="email input-standard-grey" placeholder="Details"  autoComplete="off" required />
            </div>
          </div>
          <div className="row">
            <div className="submit-block table">
              <div className="col-lg-3 table-cell">
              <input type="submit" className="btn-raised btn btn-small btn--primary" value="Submit Now" /> 
                {/* <button className="btn btn-small btn--primary">
                  <span className="text">Submit Now</span>
                </button> */}
              </div>
              <div className="col-lg-5 table-cell">
                <div className="submit-block-text">
                  Please, let us know any particular things to check and the best time
                  to contact you by phone (if provided).
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="submit-block table">
              <div className="col-lg-3 table-cell">
              {emailStatus ? emailStatus : null}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    {/* End Contact form */}
    {/* Subscribe Form */}
    <Newsletters />
    {/* End Subscribe Form */}
  </div>
</div>

        
        </>
    )
}

}
export default Getintouch;


