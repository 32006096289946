import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import {Helmet} from "react-helmet";
import Navbar from './Navbar'

const Header=()=>{

  const [scroll, setScroll] = useState(false);
	useEffect(() => {
	   window.addEventListener("scroll", () => {
		 setScroll(window.scrollY > 50);
	   });
	}, []);
  

    return(
<>


{/* Header */}
  <div className="top-bar top-bar-dark">
    <div className="container">
      <div className="top-bar-contact">
        <a  href="tel:8287876959" className="contact-item">
        +91 8287876959
        </a>
        <div className="contact-item">
          <a href="mailto:info@pluxinfosystems.com">info@pluxinfosystems.com</a>
        </div>
      </div>
     
      <div className="follow_us">
        <span>Follow us:</span>
        <div className="socials">
          <a href="https://www.facebook.com/pluxinfosystems" className="social__item" target="blank">
            <img src="https://pluxinfosystems.com/svg/circle-facebook.svg" alt="facebook" />
          </a>

          <a href="https://twitter.com/chandra353" className="social__item" target="blank">
            <img src="https://pluxinfosystems.com/svg/twitter.svg" alt="twitter" />
          </a>

          <a href="https://www.linkedin.com/company/plux-infosystems-private-limited/mycompany/?viewAsMember=true" target="blank" className="social__item">
            <img src="https://pluxinfosystems.com/img/linkedin.png" alt="linkedin" className="linkedin" />
          </a>

          <a href="https://business.google.com/website/pluxinfosystems" className="social__item" target="blank">
            <img src="https://pluxinfosystems.com/svg/google.svg" alt="google" />
          </a>
        </div>
      </div>
      <a href="#" className="top-bar-close" id="top-bar-close-js">
        <span />
        <span />
      </a>
    </div>
  </div>
  <Navbar/>
</>
    )
}

export default Header;