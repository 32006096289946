const Latestnews = (props)=>{
    const {newsTitle, newsDate, newstime} = props;
    
        return(
            <>
                <div className="latest-news-item">
                    <div className="post-additional-info">
                    <span className="post__date">
                        <i className="seoicon-clock"></i>
                        <time className="published" datetime="2016-04-23 12:00:00">
                            {`${newsDate} ,  ${newstime}`}
                        </time>
                    </span>
                    </div>
                    <h5 className="post__title entry-title ">
                        <a href="15_blog_details.html">{newsTitle}</a>
                    </h5>
                </div>        
            </>
        )
    }
    export default Latestnews;
    
    