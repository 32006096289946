import React, {useState} from 'react';
//import { Pagination } from "react-headless-pagination";

const DetailArticle = (props)=>{

    const {author, date, blogCat, subTitle, testiText, discription, authorName, authorComp, blogImg, blogTitle} = props;
    
    const [page, setPage] = useState(0);
    const handlePageChange = ({page: number}) => {
        setPage(page);
      };

    return(
        <>
            <article className="hentry post post-standard-details">
                <div className="post-thumb">
                    <img src={blogImg} alt="seo"/>
                    
                </div>
                <div className="post__content">
                    <h2 className="h2 post__title entry-title ">
                        <a href="#">{blogTitle}</a>
                    </h2>
                    <div className="post-additional-info">
                        <div className="post__author author vcard">
                            <img src="img/avatar-b-details.png" alt="author"/>
                            Posted by
                            <div className="post__author-name fn">
                                <a href="#" className="post__author-link">: {author}</a>
                            </div>
                        </div>
                        <span className="post__date">
                        <i className="seoicon-clock"></i>
                        <time className="published" datetime="2016-03-20 12:00:00">
                            {date}
                        </time>
                    </span>

                        <span className="category">
                        <i className="seoicon-tags"></i>
                        <a href="#">{blogCat}</a>
                        
                    </span>
                        <span className="post__comments">
                        <a href="#"><i className="fa fa-comment-o" aria-hidden="true"></i></a>
                        6
                    </span>
                    </div>
                    <div className="post__content-info">
                        <p className="post__subtitle">{subTitle}
                        </p>
                        <p className="post__text">{discription}
                        </p>
                        <div className="testimonial-item quote-left">
                            <h5 className="h5 testimonial-text">
                                {testiText}
                            </h5>
                            <div className="author-info-wrap table">
                                <div className="author-info table-cell">
                                    <h6 className="author-name c-primary">{authorName}</h6>
                                    <div className="author-company">{authorComp}</div>
                                </div>
                            </div>
                            <div className="quote">
                                <i className="seoicon-quotes"></i>
                            </div>
                        </div>  
                    </div>
                </div>
            </article>
            <Pagination
                currentPage={page}
                setCurrentPage={handlePageChange}
                totalPages={10}
                edgePageCount={2}
                middlePagesSiblingCount={2}
                className=""
                truncableText="..."
                truncableClassName=""
                >
                <Pagination.PrevButton className="">Previous</Pagination.PrevButton>

                <div className="flex items-center justify-center flex-grow">
                    <Pagination.PageButton
                    activeClassName=""
                    inactiveClassName=""
                    className=""
                    />
                </div>

                <Pagination.NextButton className="">Next</Pagination.NextButton>
                </Pagination>
        </>
    )
}
export default DetailArticle;