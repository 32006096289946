const PostCate = (props)=>{
    const {postCount, postTitle} = props;
    return(
        <>
            <div className="category-post-item">
                <span className="post-count">{postCount}</span>
                <a href="#" className="category-title">{postTitle}
                    <i className="seoicon-right-arrow"></i>
                </a>
            </div>
        </>
    )
}
export default PostCate;