import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import Slider from 'react-slick';
const Homeblogslider = (props)=>{

    const [sliderRef, setSliderRef] = useState(null)
    const sliderSettings = {
      arrows: false,
      slidesToShow: 2,
      dots:true,
      slidesToScroll: 3,
      infinite: true,
      className: 'innerslide',
      autoplay:false,
      speed: 1000,
      margin:30,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    }


    const [myData, setMyData] = useState([]);
  
    const getApiData = async () => {
          const res = await axios.get("https://api.pluxinfosystems.com/wp-json/wp/v2/posts");
          setMyData(res.data);
      };
  
      useEffect(() => {
        getApiData();
      }, []);

      return (
        <>
    <div className="container">
      <div className="recent-post-slider pt-6 pb-6">
           <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="heading">
                <h4 className="h1 heading-title">Latest From the Blog</h4>
                {/* <a href="14_blog.html" className="read-more">Read Our Blog
                  <i className="seoicon-right-arrow" />
                </a> */}
                <div className="heading-line">
                  <span className="short-line" />
                  <span className="long-line" />
                </div>
              </div>
            </div>
          </div>
              <div className="theme-module news-slider-module">
                <Slider ref={setSliderRef} {...sliderSettings}>
                  {myData.slice(0, 5).map((post, index) => (
                          <article key={index} className="hentry post">
                          <time className="post__date published">
                            {post.date} 
                          </time>
                          <div className="post__content">
                            <h2 className="post__title entry-title">
                               <Link to={`/blogs/${post.id}`}>{post.title.rendered}</Link> 
                            </h2> 
                            <p className="post__text" dangerouslySetInnerHTML={{__html: post.excerpt.rendered.slice(0, 100)}}></p>
                          </div>
                          <div className="post__author author vcard">
                            <div className="post-avatar">
                              <img src="img/post-author1.png" alt="author" />
                            </div>
                            <span className="post__author-name fn"> Posted by <a href="#" className="post__author-link">Admin</a></span>
                          </div>
                        </article>
                  ))}
                </Slider>
              </div>
        </div>
    </div>

        </>
      );
}

export default Homeblogslider;