import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
export default class Slide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    return (
      <div className="main-slider">
        <Slider
          asNavFor={this.state.nav2}
          ref={(slider) => (this.slider1 = slider)}
          infinite={true}
          speed= {1000}
        >
          <div>
            <div className="swiper-slide">
              <div className="container">
                <div className="row table-cell">
                  <div className="col-lg-12">
                    <div className="slider-content align-center">
                      <h1
                        className="slider-content-title"
                        data-swiper-parallax="-100"
                      >
                        Web design and Web Development
                      </h1>
                      <h5
                        className="slider-content-text"
                        data-swiper-parallax="-200"
                      >
                        Discover More About Our Web design and Web Development
                        Experties
                      </h5>
                      <div
                        className="main-slider-btn-wrap"
                        data-swiper-parallax="-300"
                      >
                        <Link
                          to="/web-design"
                          className="btn btn-medium btn--dark btn-hover-shadow"
                        >
                          <span className="text">learn more</span>
                          <span className="semicircle"></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="slider-thumb"
                      data-swiper-parallax="-400"
                      data-swiper-parallax-duration="600"
                    >
                      <img
                        src="img/webdevelopment.png"
                        alt="slider"
                        className="webdevlopment-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="swiper-slide bg-border-color">
              <div className="container">
                <div className="row table-cell">
                  <div className="col-lg-12">
                    <div className="slider-content align-center">
                      <h1
                        className="slider-content-title"
                        data-swiper-parallax="-100"
                      >
                        Search Engine Optimization!
                      </h1>
                      <h5
                        className="slider-content-text c-white"
                        data-swiper-parallax="-200"
                      >
                        Discover More About Our SEO Expertise!
                      </h5>
                      <div
                        className="main-slider-btn-wrap"
                        data-swiper-parallax="-300"
                      >
                        <Link
                          to="/seo-services"
                          className="btn btn-medium btn--dark btn-hover-shadow"
                        >
                          <span className="text">learn more</span>
                          <span className="semicircle"></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="slider-thumb"
                      data-swiper-parallax="-400"
                      data-swiper-parallax-duration="600"
                    >
                      <img src="img/slider1.png" alt="slider" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="swiper-slide bg-primary-color main-slider-bg-dark thumb-left">
              <div className="container table">
                <div className="row table-cell">
                  <div className="col-lg-5 table-cell">
                    <div className="slider-content">
                      <h3
                        className="slider-content-title"
                        data-swiper-parallax="-100"
                      >
                        {/* <span className="c-dark">Local SEO</span>
										is about bringing
										customers through
										your doors. */}
                        WHY CHOOSE US
                      </h3>
                      <h6
                        className="slider-content-text"
                        data-swiper-parallax="-200"
                      >
                        Combination of young creative designers + our
                        experienced expert web developers.
                      </h6>
                      <div
                        className="main-slider-btn-wrap"
                        data-swiper-parallax="-300"
                      >
                        <Link
                          to="/seo-services"
                          className="btn btn-medium btn--dark btn-hover-shadow"
                        >
                          <span className="text">learn more</span>
                          <span className="semicircle"></span>
                        </Link>
                        <Link
                          to="/services"
                          className="btn btn-small btn--primary"
                          data-swiper-parallax="-300"
                        >
                          <span className="text">Our Services</span>
                          <i className="seoicon-right-arrow"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 table-cell">
                    <div
                      className="slider-thumb"
                      data-swiper-parallax="-300"
                      data-swiper-parallax-duration="500"
                    >
                      <img src="img/slider2.png" alt="slider" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div> 
            <div className="swiper-slide bg-secondary-color main-slider-bg-dark">
              <div className="container table">
                <div className="row table-cell">
                  <div className="col-lg-6 table-cell">
                    <div className="slider-content">
                      <h3
                        className="h1 slider-content-title c-dark"
                        data-swiper-parallax="-100"
                      >
                        Ecommerce Solutions
                      </h3>
                      <h5
                        className="slider-content-text"
                        data-swiper-parallax="-200"
                      >
                        Our e-commerce solutions are the most effective
                      </h5>
                      <div
                        className="main-slider-btn-wrap"
                        data-swiper-parallax="-300"
                      >
                        <Link
                          to="/ecommerce"
                          className="btn btn-medium btn--dark btn-hover-shadow"
                        >
                          <span className="text">learn more</span>
                          <span className="semicircle"></span>
                        </Link>
                        <Link
                          to="/getintouch"
                          className="btn btn-medium btn-border"
                        >
                          <span className="text">GET STARTED</span>
                          <span className="semicircle"></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 table-cell">
                    <div
                      className="slider-thumb"
                      data-swiper-parallax="-300"
                      data-swiper-parallax-duration="500"
                    >
                      <img src="img/slider3.png" alt="slider" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="swiper-slide bg-orange-color main-slider-bg-dark">
              <div className="container table">
                <div className="row table-cell">
                  <div className="col-lg-12">
                    <div className="slider-content align-center">
                      <h3
                        className="h1 slider-content-title c-dark"
                        data-swiper-parallax="-100"
                      >
                        Email Marketing
                      </h3>
                      <h5
                        className="slider-content-text"
                        data-swiper-parallax="-200"
                      >
                        We create tailored marketing campaigns for each segment
                        of your audience to help advertise products and services
                        in efforts to efficiently and effectively engage new
                        customers.
                      </h5>
                      <div
                        className="main-slider-btn-wrap"
                        data-swiper-parallax="-300"
                      >
                        <a
                          href=""
                          className="btn btn-medium btn--dark btn-hover-shadow"
                        >
                          <span className="text">learn more</span>
                          <span className="semicircle"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="slider-thumb"
                      data-swiper-parallax="-400"
                      data-swiper-parallax-duration="600"
                    >
                      <img src="img/slider4.png" alt="slider" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="swiper-slide bg-green-color main-slider-bg-dark">
              <div className="container table">
                <div className="row table-cell">
                  <div className="col-lg-6 table-cell">
                    <div className="slider-content">
                      <h3
                        className="h1 slider-content-title c-dark"
                        data-swiper-parallax="-100"
                      >
                        Pay Per Click (PPC) Management
                      </h3>
                      <h5
                        className="slider-content-text"
                        data-swiper-parallax="-200"
                      >
                        Pay Per Click has an instant impact and gives your brand
                        a much larger reach and exposure as a result of first
                        page exposure on major search engines.
                      </h5>
                      <div
                        className="main-slider-btn-wrap"
                        data-swiper-parallax="-300"
                      >
                        <a
                          href=""
                          className="btn btn-medium btn--dark btn-hover-shadow"
                        >
                          <span className="text">learn more</span>
                          <span className="semicircle"></span>
                        </a>
                        <Link
                          to="/getintouch"
                          className="btn btn-medium btn-border btn-hover-shadow"
                        >
                          <span className="text">GET STARTED</span>
                          <span className="semicircle"></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 table-cell">
                    <div
                      className="slider-thumb"
                      data-swiper-parallax="-300"
                      data-swiper-parallax-duration="500"
                    >
                      <img src="img/slider5.png" alt="slider" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
        <Slider
          asNavFor={this.state.nav1}
          ref={(slider) => (this.slider2 = slider)}
          slidesToShow={6}
          swipeToSlide={true}
          focusOnSelect={true}
        >
          <div>
            <a href="#" className="slides-item main-slider-bg-light">
              <div className="content">
                <div className="text-wrap">
                  <h4 className="slides-title">Web Development</h4>
                </div>
                <div className="slides-number">01</div>
              </div>
            </a>
          </div>
          <div>
            <a
              href="#"
              className="slides-item bg-border-color main-slider-bg-light"
            >
              <div className="content">
                <div className="text-wrap">
                  <h4 className="slides-title">Search Engine Optimization</h4>
                </div>
                <div className="slides-number">02</div>
              </div>
            </a>
          </div>
          <div>
            <a href="#" className="slides-item bg-primary-color">
              <div className="content">
                <div className="text-wrap">
                  <h4 className="slides-title">Local SEO</h4>
                  <div className="slides-sub-title">
                    It is amazing to note how literally.
                  </div>
                </div>
                <div className="slides-number">03</div>
              </div>
            </a>
          </div>
          <div>
            <a href="#" className="slides-item bg-secondary-color">
              <div className="content">
                <div className="text-wrap">
                  <h4 className="slides-title">Social Media Marketing</h4>
                </div>
                <div className="slides-number">04</div>
              </div>
            </a>
          </div>
          <div>
            <a href="#" className="slides-item bg-orange-color">
              <div className="content">
                <div className="text-wrap">
                  <h4 className="slides-title">Email Marketing</h4>
                </div>
                <div className="slides-number">05</div>
              </div>
            </a>
          </div>
          <div>
            <a href="#" className="slides-item bg-green-color">
              <div className="content">
                <div className="text-wrap">
                  <h4 className="slides-title">Pay Per Click Management</h4>
                </div>
                <div className="slides-number">06</div>
              </div>
            </a>
          </div>
        </Slider>
      </div>
    );
  }
}
