import {Helmet} from "react-helmet";
import {Link } from "react-router-dom";
const MobileApp = ()=>{
    return(
      <>
         

        <div className="header-spacer" />
        <div className="content-wrapper">
            <div className="stunning-header stunning-header-bg-violet">
                <div className="stunning-header-content">
                    <h1 className="stunning-header-title">Mobile App Development Services</h1>
                    <ul className="breadcrumbs">
                    <li className="breadcrumbs-item">
                        <Link to="/">Home</Link>
                        <i className="seoicon-right-arrow" />
                    </li>
                    <li className="breadcrumbs-item active">
                        <span href="#">Mobile App Development Services</span>
                        <i className="seoicon-right-arrow" />
                    </li>
                    </ul>
                </div>
            </div>
            <div className="container pt-6 appsection">
              <section className="">
              <h4 className="text-center pb-4">MOBILE APPICATION DEVELOPMENT</h4>
              <p className="servises-text">Our team has a decade-long experience in custom mobile application development that spans a wide range of industries from retail to healthcare and energy.</p>
              <p className="servises-text">You want a team of mobile app developers who can dive into your business ecosystem. Who can explore the market and understand your needs, requirements and capabilities. Who can build a truly custom strategy and unlock the full potential of mobile technology for your company.</p>
              <p className="servises-text">This is what custom mobile app development is about. We get to know your business and tailor our application development services to your goals and expectations.</p>  
              </section>



              
              <div className="row pb30">
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <h4 className="text-center pb-4 pt-8">WHAT WE DO</h4>
                  <p className="servises-text">We provide custom mobile app development services. This includes expert business analysis, design and development of your mobile application from idea to launch, integrating the new product into your infrastructure and providing further optimization and scale-up on demand.
                  We build mobile applications on different platforms using top-notch technologies and proven approaches. Our smartphone app development services include:</p>
                </div>
                  
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div className="app-sec-box">
                    <div className="app-sec-img">
                      <img src="img/plux_app1.png"/>
                    </div>
                    <div className="app-box-title">
                     Leveraging next-gen technologies, intelligent algorithms and modern UI
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div className="app-sec-box text-center">
                    <div className="app-sec-img">
                      <img src="img/plux_app2.png"/>
                    </div>
                    <div className="app-box-title">
                     Leveraging next-gen technologies, intelligent algorithms and modern UI
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <div className="app-sec-box">
                    <div className="app-sec-img">
                      <img src="img/plux_app3.png"/>
                    </div>
                    <div className="app-box-title">
                     Leveraging next-gen technologies, intelligent algorithms and modern UI
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </>
    );
  }
 
  export default MobileApp;