import React, { useState, useEffect  } from "react";
import "./navbar.css";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";

import { NavLink } from "react-router-dom";

const Navbar = () => {


  // alert('ergdfgd');

  const [showMediaIcons, setShowMediaIcons] = useState(false);

  const [scroll, setScroll] = useState(false);
  const toggle = () => setScroll(!scroll);

	useEffect(() => {
	   window.addEventListener("scroll", () => {
		 setScroll(window.scrollY > 50);
	   });
	}, []);
  return (
    <>
    <header className={scroll ? "header animated headroom--not-bottom headroom--not-top swingInX" : "header animated headroom--not-bottom swingInX headroom--top"}>
      <div className="container">
        <nav className="main-nav">
          {/* 1st logo part  */}
          <div className="logo">
            <a href="https://pluxinfosystems.com/">
              <img className="mainlogo" src="https://pluxinfosystems.com/img/logo.png" alt="Seosight" />
            </a>
            
          </div>

          {/* 2nd menu part  */}
          <div
            className={
              showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
            }>
            <ul className="primary-menu-menu">
              <li className="menu-item-has-children">
                <NavLink to="/" onClick={toggle}>Home</NavLink>
              </li>
              
              <li className>
                <NavLink to="services" onClick={toggle}>Services</NavLink>
              </li>
              <li className>
                <NavLink to="/about" onClick={toggle}>About Us</NavLink>
              </li>
              <li className="has-megamenu menu-item-has-children">
                <NavLink to="portfolio" onClick={toggle}>Portfolio</NavLink>
              </li>
              {/* <li className>
                <Link to="ourteam">Our Team</Link>
              </li> */}
			        <li className>
                <NavLink to="blog" onClick={toggle}>Blog</NavLink>
              </li>
              <li className>
                <NavLink to="carrer" onClick={toggle}>Career</NavLink>
              </li>
              <li className>
                <NavLink to="getintouch" onClick={toggle}>Get In Touch</NavLink>
              </li>
            </ul>
            
          </div>
          <div className="social-media">
            <div className="hamburger-menu">
              <a href="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
                <GiHamburgerMenu />
              </a>
            </div>
          </div>
        </nav>
      </div>
    </header>

    </>
  );
};

export default Navbar;
