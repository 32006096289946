import React from 'react';
import Footer from '../Component/Footer';
import Header from '../Component/Header';
import Newsletters from './Newsletters';
import {Helmet} from "react-helmet";
import { Link, } from "react-router-dom";

const Services=()=>{
    return(
        <>
        
        
<div>
  <div className="header-spacer " />
  <div className="content-wrapper services-content">
    <div className="stunning-header stunning-header-bg-violet">
      <div className="stunning-header-content">
        <h1 className="stunning-header-title">Our Services</h1>
        <ul className="breadcrumbs">
          <li className="breadcrumbs-item">
            <Link to="home">Home</Link>
            <i className="seoicon-right-arrow" />
          </li>
          <li className="breadcrumbs-item active">
            <span>Our Services</span>
            <i className="seoicon-right-arrow" />
          </li>
        </ul>
      </div>
    </div>
    {/* End Stunning Header */}
    {/* Overlay Search */}
    <div className="overlay_search">
      <div className="container">
        <div className="row">
          <div className="form_search-wrap">
            {/* <form>
              <input className="overlay_search-input" placeholder="Type and hit Enter..." type="text" />
              <a href="#" className="overlay_search-close">
                <span />
                <span />
              </a>
            </form> */}
          </div>
        </div>
      </div>
    </div>
    {/* End Overlay Search */}
    {/* Services Items */}
    <div className="container pt-6">
      <div className="row pb30">
        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 expert_area">
          <div className="servises-item " style={{minHeight:828,}}>
            <div className="servises-item__thumb">
              <img src="img/services1.png" alt="service" />
            </div>
            <div className="servises-item__content">
              <h4 className="servises-title">We Are Also Expert In</h4>
              <p className="servises-text">Our experts team of web developers do all the work behind the scenes that ensure your website runs optimally for you, your clients, and search engines. We work wireframes, carry out user journey mapping, and test processes, with our findings, ultimately helping us gain in-depth insight into any problems
              </p>
            </div>
            <Link to="/seo-services" className="btn btn-medium btn--dark btn-hover-shadow">
              <span className="text">Free SEO Analysis</span>
              <span className="semicircle" />
            </Link>
          </div>
        </div>




        {/* <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
          <div className="services-main">
            <div className="heading">
              <h4 className="h1 heading-title">We Are Also Expert In</h4>
              <div className="heading-line">
                <span className="short-line" />
                <span className="long-line" />
              </div>
              
              <p className="heading-text">Our experts team of web developers do all the work behind the scenes that ensure your website runs optimally for you, your clients, and search engines. We work wireframes, carry out user journey mapping, and test processes, with our findings, ultimately helping us gain in-depth insight into any problems.
              </p>
            </div>
            <Link to="/seo-services" className="btn btn-medium btn--dark btn-hover-shadow">
              <span className="text">Free SEO Analysis</span>
              <span className="semicircle" />
            </Link>
          </div>
        </div> */}
        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
          <div className="servises-item bg-violet-color" >
            <div className="servises-item__thumb">
              <img src="img/services1.png" alt="service" />
            </div>
            <div className="servises-item__content">
              <h4 className="servises-title">Static Web Designing</h4>
              <p className="servises-text">Static content refers to the regular files on your server which are handled with the help of simplest methods available on the web browser. To display static content in a web browser a website is needed and designing of such a website is known as Static Website Designing.
              </p>
            </div>
            
            <Link to="/web-design" className="read-more">
              <i className="seoicon-right-arrow" />
            </Link>
			    
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
          <div className="servises-item bg-primary-color">
            <div className="servises-item__thumb">
              <img src="img/services2.png" alt="service" />
            </div>
            <div className="servises-item__content">
              <h4 className="servises-title">Search Engine Marketing </h4>
              <p className="servises-text">Search Engine Marketing (SEM) is a form of Internet Marketing that involves the promotion of websites by increasing their visibility in search engine results pages (SERPs) through optimization of the content of your website along with proper advertising of your website. It involves SEO along with pay per click listings.
              </p>
            </div>
			    {/* <a href="05_service_details_localseo.html" className="read-more"><i className="seoicon-right-arrow" /></a> */}
            <Link to="/seo-services" className="read-more">
              <i className="seoicon-right-arrow" />
            </Link>
          </div>
        </div>
      </div>
      <div className="row pb-6">
        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
          <div className="servises-item bg-secondary-color">
            <div className="servises-item__thumb">
              <img src="img/services3.png" alt="service" />
            </div>
            <div className="servises-item__content">
              <h4 className="servises-title">Web Development</h4>
              <p className="servises-text">Web Development means the development of web pages over a domain in order to show a result. It is a very broad field, it ranges from static text to e-commerce websites and from client scripting to network security. Web development is all about how distinguished and optimized one’s product is from others.
              </p>
            </div>
			      {/* <a href="06_service_detail.html" className="read-more"><i className="seoicon-right-arrow" /></a> */}
            <Link to="/Web-development" className="read-more">
              <i className="seoicon-right-arrow" />
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
          <div className="servises-item bg-orange-color">
            <div className="servises-item__thumb">
              <img src="img/services4.png" alt="service" />
            </div>
            <div className="servises-item__content">
              <h4 className="servises-title">Graphic Designing</h4>
              <p className="servises-text">Graphic Designing requires creativity along with the vision to generate something astounding which can instantly grab someone’s attention. WebRiders, leading website designing company in India, is the pathfinder in the field of graphic designing
              </p>
            </div>
            {/* <Link to="/graphic" className="read-more"><i className="seoicon-right-arrow" /></Link> */}
            <Link to="/graphic" className="read-more">
              <i className="seoicon-right-arrow" />
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
          <div className="servises-item bg-green-color">
            <div className="servises-item__thumb">
              <img src="img/services5.png" alt="service" />
            </div>
            <div className="servises-item__content">
              <h4 className="servises-title">Dynamic Web Designing </h4>
              <p className="servises-text">Dynamic content refers to the type of content that is generated when a request for the page is raised by the user in the web browser. For such type of content, one needs a dynamic website and designing of such a website is known as Dynamic Website Designing.
              </p>
            </div>
			    {/* <a href="08_service_ppc_management.html" className="read-more"><i className="seoicon-right-arrow" /></a>  */}
            <Link to="/web-host" className="read-more">
              <i className="seoicon-right-arrow" />
            </Link>
          </div>
        </div>
      </div>
    </div>
    {/* End Services Items */}
    {/* Features Item */}
    <div className="container-fluid">
      <div className="row bg-border-color pb-3 pt-3">
        <div className="container">
          <div className="row pb30">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="features-item">
                <div className="features-item__thumb">
                  <img src="img/features1.png" alt="image" />
                </div>
                <div className="features-content">
                  <span className="features-title">Local Search Strategy</span>
                  <p className="features-text">The pain itself is the love
                  </p>
                </div>
				        <a className="read-more" href="#">Read More
                  <i className="seoicon-right-arrow" />
                 </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="features-item">
                <div className="features-item__thumb">
                  <img src="img/features2.png" alt="image" />
                </div>
                <div className="features-content">
                  <span className="features-title">Maps Search</span>
                  <p className="features-text">Clarity is also a dynamic process that follows the changing behavior of readers.
                  </p>
                </div>
                <span className="read-more" href="#">Read More
                  <i className="seoicon-right-arrow" />
                </span>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="features-item">
                <div className="features-item__thumb">
                  <img src="img/features3.png" alt="image" />
                </div>
                <div className="features-content">
                  <span className="features-title">Link Building &amp; Content</span>
                  <p className="features-text">Investigations have shown that readers read much more often than they read during the fourteenth and fifteenth centuries.
                  </p>
                </div>
                <a className="read-more" href="#">Read More
                  <i className="seoicon-right-arrow" />
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="features-item">
                <div className="features-item__thumb">
                  <img src="img/features4.png" alt="image" />
                </div>
                <div className="features-content">
                  <span className="features-title">Paid Search Advertising</span>
                  <p className="features-text">Clarity is also a dynamic process that follows the changing behavior of readers.
                  </p>
                </div>
                <a className="read-more" href="#">Read More
                  <i className="seoicon-right-arrow" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="features-item">
                <div className="features-item__thumb">
                  <img src="img/features6.png" alt="image" />
                </div>
                <div className="features-content">
                  <span className="features-title">Custom Website Design</span>
                  <p className="features-text">Investigations have shown that readers read much more often than they read during the fourteenth and fifteenth centuries.
                  </p>
                </div>
                <a className="read-more" href="#">Read More
                  <i className="seoicon-right-arrow" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="features-item">
                <div className="features-item__thumb">
                  <img src="img/features5.png" alt="image" />
                </div>
                <div className="features-content">
                  <span className="features-title">Custom Email Design</span>
                  <p className="features-text">The pain itself is the love
                  </p>
                </div>
                <a className="read-more" href="#">Read More
                  <i className="seoicon-right-arrow" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Features Item */}
    <div className="container pt-3 pb-3">
      <div className="row">
        <div className="col-lg-12">
          <div className="heading">
            <h4 className="h1 heading-title">Our Product Based Services</h4>
            <div className="heading-line">
              <span className="short-line" />
              <span className="long-line" />
            </div>
            <p className="heading-text c-dark">Investigations have shown that readers read much more often than they read during the fourteenth and fifteenth centuries.
            </p>
          </div>
        </div>
      </div>
      <div className="row pb30">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ul className="list list--secondary">
            <li>
              <i className="seoicon-check" />
              <a href="#">The pain itself is a lot of pain, but the pain is a nice thing to do, but the pain can be put down to the pain that's why it's going to come.
              </a>
            </li>
          </ul>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ul className="list list--secondary">
            <li>
              <i className="seoicon-check" />
              <a href="#">
                It is amazing to note how gothic letter, which I now think a little, has preferred literary forms of automatic pain.
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mb30">
          <Link to="seo-services" className="btn btn-medium btn--blue">
            <span className="text">Free SEO Consultation</span>
            <span className="semicircle" />
          </Link>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mb30">
          <Link to="/getintouch" className="btn btn-medium btn--yellow">
            <span className="text">Request a Free Quote</span>
            <span className="semicircle" />
          </Link>
        </div>
      </div>
    </div>
    {/* Subscribe Form */}
    <Newsletters />
    {/* End Subscribe Form */}
  </div>
</div>

        </>
    )
}

export default Services;


