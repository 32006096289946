import React, { Component } from "react";
import Slider from "react-slick";

export default class Clientslider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
          <div className="testimonial-slider-item swiper-slide">
                <div className="testimonial-content">
                    <p className="text" data-swiper-parallax={-200}>"Kailash has worked with me on various projects and has always exceeded my expectations. Work always completed in a timely manner. Professional work, and very communicative. I highly recommend Kailash for your future project work."
                    </p>
                    <a href="#" className="author" data-swiper-parallax={-150}>RICHARD D.</a>
                    <a href="#" className="company" data-swiper-parallax={-150}>tipster challenge</a>
                </div>
                {/* <div className="avatar" data-swiper-parallax={-50}>
                    <img src="img/avatar.png" alt="avatar" />
                </div> */}
            </div>
          </div>
          <div>
          <div className="testimonial-slider-item swiper-slide">
                <div className="testimonial-content">
                    <p className="text" data-swiper-parallax={-200}>"Kailash has demonstrated extraordinary brilliance while working with eVanik Networks. His knack for understanding the problem and solving it with out of the box thinking is what is really expected out of great engineers. I wish him all the best in his future endeavours"
                    </p>
                    <a href="#" className="author" data-swiper-parallax={-150}>Mayank Kumar</a>
                    <a href="#" className="company" data-swiper-parallax={-150}>eVanik Networks</a>
                </div>
                {/* <div className="avatar" data-swiper-parallax={-50}>
                    <img src="img/avatar.png" alt="avatar" />
                </div> */}
                </div>
          </div>
          <div>
          <div className="testimonial-slider-item swiper-slide">
            <div className="testimonial-content">
                <p className="text" data-swiper-parallax={-200}>"Kailash has worked with me on various projects and has always exceeded my expectations. Work always completed in a timely manner. Professional work, and very communicative. I highly recommend Kailash for your future project work."
                </p>
                <a href="#" className="author" data-swiper-parallax={-150}>RICHARD D.</a>
                <a href="#" className="company" data-swiper-parallax={-150}>tipster challenge</a>
            </div>
            {/* <div className="avatar" data-swiper-parallax={-50}>
                <img src="img/avatar.png" alt="avatar" />
            </div> */}
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}