import React from 'react';
import Footer from '../Component/Footer';
import Header from '../Component/Header';
import Newsletters from './Newsletters';
import {Helmet} from "react-helmet";
import {Link } from "react-router-dom";

const Ourteam=()=>{
  const pscsstyle = {
    backgroundColor:"#060607",
  }
    return(
        <>
       

<div>
<div className="header-spacer" /> 
  <div className="content-wrapper">
    {/* Stunning header */}
    <div className="stunning-header stunning-header-bg-lightblue">
      <div className="stunning-header-content">
        <h1 className="stunning-header-title">Our Team</h1>
        <ul className="breadcrumbs">
          <li className="breadcrumbs-item">
            <Link to="/">Home</Link>
            <i className="seoicon-right-arrow" />
          </li>
          <li className="breadcrumbs-item active">
            <span>Our Team</span>
            <i className="seoicon-right-arrow" />
          </li>
        </ul>
      </div>
    </div>
    {/* End Stunning header */}
    {/*  Overlay Search */}
    <div className="overlay_search">
      <div className="container">
        <div className="row">
          <div className="form_search-wrap">
            <form>
              <input className="overlay_search-input" placeholder="Type and hit Enter..." type="text" />
              <a href="#" className="overlay_search-close">
                <span />
                <span />
              </a>
            </form>
          </div>
        </div>
      </div>
    </div>
 
    {/* Slider Profit */}

    {/* Team members */}
    <div className="container">
      <div className="row pt-6 mb30">
        <div className="col-lg-12">
          <div className="heading align-center">
            <h4 className="h1 heading-title">Meet with Our Best Experts</h4>
            <div className="heading-line">
              <span className="short-line" />
              <span className="long-line" />
            </div>
            <p className="heading-text">Massive Dynamic has over 10 years of experience in Design. We take pride in delivering Intelligent Designs and Engaging Experiences for clients all over the World.</p>
          </div>
        </div>
      </div>
      <div className="row pb-6">
        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <div className="teammembers-item">
            <img src="img/teammember1.png" alt="team member" />
            <h5 className="teammembers-item-name">Kailash Chandra</h5>
            <p className="teammembers-item-prof">
            Chief Technical Officer</p>
            <div className="socials">
              <a href className="social__item">
                <img src="svg/circle-facebook.svg" alt="facebook" />
              </a>
              <a href className="social__item">
                <img src="svg/twitter.svg" alt="twitter" />
              </a>
              <a href className="social__item">
                <img src="svg/google.svg" alt="google" />
              </a>
              <a href className="social__item">
                <img src="svg/youtube.svg" alt="youtube" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <div className="teammembers-item">
            <img src="img/teammember2.png" alt="team member" />



            <h5 className="teammembers-item-name">Roshni Prajapati</h5>
            <p className="teammembers-item-prof">Core developer</p>
            <div className="socials">
              <a href className="social__item">
                <img src="svg/circle-facebook.svg" alt="facebook" />
              </a>
              <a href className="social__item">
                <img src="svg/twitter.svg" alt="twitter" />
              </a>
              <a href className="social__item">
                <img src="svg/google.svg" alt="google" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <div className="teammembers-item">
            <img src="img/teammember3.png" alt="team member" />
            
            <h5 className="teammembers-item-name">Vikash kumar</h5>
            <p className="teammembers-item-prof">Frontend developer</p>
            <div className="socials">
              <a href className="social__item">
                <img src="svg/circle-facebook.svg" alt="facebook" />
              </a>
              <a href className="social__item">
                <img src="svg/twitter.svg" alt="twitter" />
              </a>
              <a href className="social__item">
                <img src="svg/google.svg" alt="google" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <div className="teammembers-item">
            <img src="img/teammember4.png" alt="team member" />
            <h5 className="teammembers-item-name">Sujeet Kumar</h5>
            <p className="teammembers-item-prof">Web Designar</p>
            <div className="socials">
              <a href className="social__item">
                <img src="svg/circle-facebook.svg" alt="facebook" />
              </a>
              <a href className="social__item">
                <img src="svg/twitter.svg" alt="twitter" />
              </a>
              <a href className="social__item">
                <img src="svg/google.svg" alt="google" />
              </a>
              <a href className="social__item">
                <img src="svg/youtube.svg" alt="youtube" />
              </a>
            </div>
          </div>
        </div>
		
		<div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <div className="teammembers-item">
            <img src="img/teammember4.png" alt="team member" />
            <h5 className="teammembers-item-name">Mohd. Kasim</h5>
            <p className="teammembers-item-prof">Laravel developer</p>
            <div className="socials">
              <a href className="social__item">
                <img src="svg/circle-facebook.svg" alt="facebook" />
              </a>
              <a href className="social__item">
                <img src="svg/twitter.svg" alt="twitter" />
              </a>
              <a href className="social__item">
                <img src="svg/google.svg" alt="google" />
              </a>
              <a href className="social__item">
                <img src="svg/youtube.svg" alt="youtube" />
              </a>
            </div>
          </div>
        </div>
		
		    
      </div>
    </div>
    {/* End Team members */}
    {/* Info boxes */}
    <div className="container-fluid">
      <div className="row pt-6 pb-6 bg-border-color">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 col-sx-12">
              <div className="info-box--standard">
                <div className="info-box-image f-none">
                  <img src="img/info-box20.png" alt="image" />
                </div>
                <div className="info-box-content">
                  <h5 className="info-box-title">Excellent Support</h5>
                  <p className="text">The pain itself is the love
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-sx-12">
              <div className="info-box--standard">
                <div className="info-box-image f-none">
                  <img src="img/info-box21.png" alt="image" />
                </div>
                <div className="info-box-content">
                  <h5 className="info-box-title">Awesome Team</h5>
                  <p className="text">Clarity is also a dynamic process that follows the changing behavior of readers
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-sx-12">
              <div className="info-box--standard">
                <div className="info-box-image f-none">
                  <img src="img/info-box22.png" alt="image" />
                </div>
                <div className="info-box-content">
                  <h5 className="info-box-title">Faster Performance</h5>
                  <p className="text">The pain itself is the love
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Info boxes */}
  
    {/* Clients */}
    <div className="container">
      <div className="row pt-6 pb-6">
        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <div className="heading">
            <h4 className="h1 heading-title">Our Valuable Clients</h4>
            <div className="heading-line">
              <span className="short-line" />
              <span className="long-line" />
            </div>
            <p className="heading-text">It is wonderful to note that the Gothic letter, which we now think a little clear, preferred the literary forms of the humanities during the fourteenth and fifteenth centuries
            </p>
          </div>
          {/* <a href="09_our_clients.html" className="btn btn-medium btn--dark mb30">
            <span className="text">View all Customers</span>
            <span className="semicircle" />
			</a> */}
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <div className="row">
          <div className="clients-item-wrap">
              <div className="client-item mb60 our_client_item">
                <a href="https://web.kailashchandra.com/tosall_client/" className="client-image">
                <img src="img/tosall-logo-plux.png" alt="logo" className="hover" />
                </a>
              </div>
              <div className="client-item mb60 our_client_item">
                <a href="https://tipsterchallenge.com/pluxinfo_2021/" className="client-image">
                <img src="img/logo-tipster.jpg" alt="logo" className="hover" />
                </a>
              </div>
              <div className="client-item mb60 our_client_item">
                <a href="https://www.wecharge.co.in/" className="client-image">
                <img src="img/logo-wechage.png" alt="logo" className="hover" />
                </a>
              </div>
              <div className="client-item mb60 our_client_item">
                <a href="https://web.kailashchandra.com/pscs/front/" className="client-image">
                <img src="img/logo-pscs.png" alt="logo" className="hover" style={pscsstyle}/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Clients */}
    {/* Subscribe Form */}
    <Newsletters />
    {/* End Subscribe Form */}
  </div>
</div>



        </>
    )
}

export default Ourteam;


