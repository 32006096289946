import {Link,} from "react-router-dom";
import React from 'react';


const Footer =()=>{
    return(
<>
 {/* Footer */}
 <footer className="footer js-fixed-footer" id="site-footer">
    <div className="container">
      <div className="row">
        <div className="info">
          <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
            <div className="heading">
              <h3 className="heading-title">PLUX INFOSYSTEMS PVT. LTD.</h3>
              <div className="heading-line">
                <span className="short-line" />
                <span className="long-line" />
              </div>
              <p className="heading-text">Plux Infosystems is an India based web designing, Internet marketing, and mobile application development company with a unique approach towards the web. For some of our customers, we are an internet marketing company who can help their website rank better in Google, to others we are web developers while to some others we are the people who can create applications they want to see the build. We can’t get enough of web and aspire to help each of our clients do more with it.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-lg-offset-1 col-md-4 col-md-offset-1 col-sm-12 col-xs-12">
            <div className="services">
              <div className="heading">
                <h3 className="heading-title">Services Provided</h3>
                <div className="heading-line">
                  <span className="short-line" />
                  <span className="long-line" />
                </div>
              </div>
              <ul className="list list--primary list-1">
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true" />
                  <Link to="/seo-services">Seo Services</Link>
                </li>
                {/* <li>
                  <i className="fa fa-caret-right" aria-hidden="true" />
                  <Link to="/web-host">Web Hosting</Link>
                </li> */}
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true" />
                  <Link to="/web-design">Web Designing</Link>
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true" />
                  <Link to="/Web-development">Web Development</Link>
                </li>
                
              </ul>
              <ul className="list list--primary list-2">
                
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true" />
                  <Link to="/ecommerce" >Ecommerce Solutions</Link>
                </li>
                
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true" />
                  <Link to="/magento-development">Magento Development</Link>
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true" />
                  <Link to="/mobile-app">Mobile Apps Development</Link>
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="contacts">
          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <div className="contacts-item">
              <div className="icon js-animate-icon">
                <svg enableBackground="new 0 0 64 64" version="1.1" viewBox="0 0 64 64" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg"><path d="  M45.1,44.2C42.9,42,39.6,40,37,42.6c-1.8,1.8-2.6,3.9-2.6,3.9s-4.3,2.3-11.7-5.2s-5.2-11.7-5.2-11.7s2.1-0.8,3.9-2.6  c2.6-2.6,0.6-5.9-1.7-8.1c-2.7-2.7-6.2-4.9-8.2-2.9c-3.7,3.7-4.4,8.4-4.4,8.4S9,35.5,18.7,45.3s20.9,11.6,20.9,11.6s4.7-0.7,8.4-4.4  C50,50.4,47.8,46.9,45.1,44.2z" fill="none" stroke="#fcb03b" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} /><path d="  M18.4,12.2C22.2,9.5,26.9,8,32,8c13.3,0,24,10.8,24,24c0,4-1.3,9-4.4,12.2" fill="none" stroke="#fcb03b" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} /><path d="  M27.3,55.6c-9.8-1.9-17.5-9.8-19.1-19.7" fill="none" stroke="#fcb03b" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} /><path d="  M30,21c0,0,4.4,0,5.2,0c1.2,0,1.8,0.2,1.8,1.1s0,0.7,0,1.3c0,0.6,0,1.4-1.6,2.5c-2.3,1.6-5.6,3.8-5.6,5.1c0,1.6,0.7,2,1.8,2  s5.3,0,5.3,0" fill="none" stroke="#fcb03b" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} /><path d="  M40,21c0,0,0,2.8,0,3.8S39.9,27,41.5,27c1.6,0,4.5,0,4.5,0v-6.1V33" fill="none" stroke="#fcb03b" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} /></svg>
              </div>
              <div className="content">
                <a href="tel:8287876959" className="title">+91 8287876959</a>
                <p className="sub-title">Mon-Sat 9am-6pm</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <div className="contacts-item">
              <div className="icon js-animate-icon">
                <svg enableBackground="new 0 0 64 64" version="1.1" viewBox="0 0 64 64" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg"><polyline fill="none" points="  54,17 32,36 10,17 " stroke="#f15b26" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} /><line fill="none" stroke="#f15b26" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} x1="10.9" x2={26} y1={48} y2={36} /><path d="  M32.7,49H13c-2.2,0-4-1.8-4-4V19c0-2.2,1.8-4,4-4h38c2.2,0,4,1.8,4,4v15.5" fill="none" stroke="#f15b26" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} /><circle cx="44.9" cy="43.1" fill="none" r="10.1" stroke="#f15b26" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} /><path d="  M44,41.4c0,0-1.3,3.4-0.9,5.1c0.4,1.7,2.6,2.1,3.7,1.1" fill="none" stroke="#f15b26" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} /><g><circle cx="45.4" cy="38.3" fill="#DCE9EE" r="0.9" /><path d="M45.4,37.3c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9C46.4,37.8,46,37.3,45.4,37.3   L45.4,37.3z" fill="#f15b26" /></g></svg>
              </div>
              <div className="content">
                <a href="mailto:info@pluxinfosystems.com" className="title">info@pluxinfosystems.com</a>
                <p className="sub-title">online support</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <div className="contacts-item">
              <div className="icon js-animate-icon">
                <svg enableBackground="new 0 0 64 64" version="1.1" viewBox="0 0 64 64" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg"><polygon fill="none" points="  38.7,36.4 56,32 38.7,27.6 42,22 36.4,25.3 32,8 27.6,25.3 22,22 25.3,27.6 8,32 25.3,36.4 22,42 27.6,38.7 32,56 36.4,38.7 42,42   " stroke="#3cb878" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} /><circle cx={32} cy={32} fill="none" r={4} stroke="#3cb878" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} /><path d="  M26.1,53.2c-7.9-2.2-13.9-8.6-15.6-16.7" fill="none" stroke="#3cb878" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} /><path d="  M53.5,36.9c-1.8,8.1-8.2,14.6-16.3,16.5" fill="none" stroke="#3cb878" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} /><path d="  M36.9,10.5c8.2,1.9,14.7,8.3,16.6,16.6" fill="none" stroke="#3cb878" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} /><path d="  M10.5,27.1c1.9-8.2,8.3-14.6,16.4-16.5" fill="none" stroke="#3cb878" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={2} /></svg>
              </div>
              <div className="content">
                <Link to="/" className="title">PLUX INFOSYSTEMS PVT. LTD.</Link>
                <p className="sub-title">2nd Floor, A- 81, Block A, Sector 65, Noida - 201301</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="sub-footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <span>
              Copyright © 2021 <Link to="/" className="sub-footer__link">PLUX INFOSYSTEMS PVT. LTD.</Link>
            </span>
            <a className="back-to-top" href="#">
              <svg className="back-to-top">
                <use xlinkHref="#to-top" />
              </svg> 
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
  {/* End Footer */}
</>
    )
}

export default Footer;